import React from 'react';
//import { Link } from 'react-router-dom';
//import { Swiper, SwiperSlide } from 'swiper/react';
//import MealCalendarPresence from './MealCalendarPresence';
import 'dayjs/locale/fr';
import { useCookies } from 'react-cookie';
import dayjs from 'dayjs';
import { Badge } from '@mui/material';
import { DatePicker, LocalizationProvider, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const MealCalendar = ({ reservations, currentDate, dayLimit }) => {
    const [cookie] = useCookies();
    const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id'] ?? null

    const today = dayjs();
    const current = dayjs(currentDate);
    const dayLimitDate = dayjs(dayLimit);

    /*let index = 0
    let found = false
    Object.keys(reservations).map((key) => {
        if( !found && currentDate == key ){
            found = true
            return index
        }

        if( !found ){
            index++
        }
    })

    if( index > 2 ){
        index = index-2
    }else{
        index = 0
    }*/

    const handleDateChange = (date) => {
        window.location = `/meal/${date.format('YYYY-MM-DD')}`;
    };

    const shouldDisableDate = (date) => {
        return date.isBefore(dayLimitDate) || undefined === reservations[date.format('YYYY-MM-DD')] || reservations[date.format('YYYY-MM-DD')].closed
    };

    const dateBefore = () => {
        window.location = `/meal/${current.add(-1, 'day').format('YYYY-MM-DD')}`;
    };

    const dateAfter= () => {
        window.location = `/meal/${current.add(1, 'day').format('YYYY-MM-DD')}`;
    };

    return (null != canteenId &&
        <>
            <div className='calendar_reservation d-flex flex-column w-100 justify-content-center align-items-center'>
                <div className='d-flex justify-content-center align-items-center mb-3'>
                    <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                    <div className='calendar-datepicker'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                            <DatePicker
                                onChange={handleDateChange}
                                minDate={today.add(-1, 'days')}
                                maxDate={dayLimitDate.add(60, 'days')}
                                defaultValue={current}
                                loading={false}
                                shouldDisableDate={shouldDisableDate}
                                slots={{
                                    day: ServerDay,
                                }}
                                slotProps={{
                                    day: {
                                        reservations,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                    <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                </div>
            </div>
        </>
    )

    /*return ( null != canteenId &&
        <>
            <Swiper className='swipper-date calendar-bg d-flex w-100 justify-content-center' slidesPerView="5.5" initialSlide={index}>
                {
                    Object.keys(reservations).map((key) => {
                        return (
                            <SwiperSlide key={key}>
                                { key === currentDate ?
                                    <div className='box-calendar current-day d-flex align-items-center p-1 rounded-top flex-column'>
                                        <div className='calendar-day day-after'>
                                            <div className='d-flex justify-content-center align-items-center position-relative'>
                                                <div className='calenday-day-name'>{reservations[key].format}</div>
                                                { reservations[key].reserved !== null &&
                                                    <MealCalendarPresence presence={reservations[key].reserved}></MealCalendarPresence>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : key < today ?
                                        <div className='box-calendar d-flex align-items-center p-1 flex-column'>
                                            <Link key={key} to={`/meal-review/${key}`}>
                                                <div className='calendar-day day-before'>
                                                    <div className='d-flex justify-content-center align-items-center position-relative'>
                                                        <div className='calenday-day-name'>{reservations[key].format}</div>
                                                        { reservations[key].reserved !== null &&
                                                        <MealCalendarPresence presence={reservations[key].reserved}></MealCalendarPresence>
                                                        }
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        :
                                        (reservations[key].closed) ?
                                            <div className='box-calendar d-flex align-items-center p-1 flex-column'>
                                                <div className='calendar-day day-before'>
                                                    <div className='d-flex justify-content-center align-items-center position-relative'>
                                                        <div className='calenday-day-name'>{reservations[key].format}</div>
                                                        { reservations[key].reserved !== null &&
                                                        <MealCalendarPresence presence={reservations[key].reserved}></MealCalendarPresence>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='box-calendar d-flex align-items-center p-1 flex-column'>
                                                <Link key={key} to={`/meal/${key}`}>
                                                    <div className='calendar-day day-after'>
                                                        <div className='d-flex justify-content-center align-items-center position-relative'>
                                                            <div className='calenday-day-name'>{reservations[key].format}</div>
                                                            { reservations[key].reserved !== null &&
                                                            <MealCalendarPresence presence={reservations[key].reserved}></MealCalendarPresence>
                                                            }
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                }
                            </SwiperSlide>
                        )
                    })
                }

            </Swiper>
        </>
    )*/
}

export default MealCalendar

function ServerDay(props) {
    const { reservations = [], day, outsideCurrentMonth, ...other } = props;

    let badgeContent = undefined;
    let color = 'default';
    if (!props.outsideCurrentMonth && undefined !== reservations[day.format('YYYY-MM-DD')]) {
        if (true === reservations[day.format('YYYY-MM-DD')].reserved) {
            badgeContent = ' ';
            color = 'success';
        } else if (false === reservations[day.format('YYYY-MM-DD')].reserved) {
            badgeContent = ' ';
            color = 'error';
        }
    }

    return (
        <Badge key={props.day.toString()} overlap="circular" badgeContent={badgeContent} variant='dot' color={color}>
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </Badge>
    );
}
