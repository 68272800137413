import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { useCookies } from 'react-cookie';

const UserFamily = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#E5E5E5');

    // Global
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [displayUserChildren, setDisplayUserChildren] = useState(false);
    const [codeFamily, setCodeFamily] = useState('');
    const [childrenList, setChildrenList] = useState([]);
    const { auth, setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const [, setCookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_child`]);

    const getChildren = async () => {
        setIsLoading(true);
        const result = await axiosPrivate.get('/guests/child/list', { withCredentials: true });
        setChildrenList(result.data.children);
        setCodeFamily(result.data.code);
        setDisplayUserChildren(true);
        setIsLoading(false);
    };

    useEffect(() => {
        // Redirect if not choice
        if (auth?.userId === null) {
            navigate('/my-profile');
        } else {
            getChildren();
        }
    }, []);

    const changeChildren = async (id) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const response = await axiosPrivate.put('/guests/child/change', {
                childId: parseInt(id)
            },{
                withCredentials: true
            });
            setAuth({ ...auth, childId: response.data.childId});
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, childId: response.data.childId }, { path: '/' })
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {'id': response.data.childId, 'name': response.data.childName, 'point': response.data.childPoint}, { path: '/' })

            const result = await axiosPrivate.get('/guests/canteen', {
                params: {
                    childId: parseInt(id),
                },
                withCredentials: true,
            })
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, {id: result.data.canteen.id, name: result.data.canteen.name}, { path: '/' })
            setFlash({ message: 'L\'enfant par défaut a été changé', type: 'success' });
            window.location = '/home'
        } catch (err) {
            console.log(err);
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    const updateChildren = async (id, event) => {
        navigate('/update-child', { state: { childId: id } })
        event.stopPropagation();
    }

    const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement('textarea'); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
    const copyToClipboard = (code) => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(code);
        } else {
            unsecuredCopyToClipboard(code);
        }

        setFlash({ message: 'Code Copié', type: 'success' });
        setShowFlash(true);
    };

    return (
        <>
            { displayUserChildren &&
                <div className='profil-page d-flex min-vh-100 justify-content-center flex-column'>
                    <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                        <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                            <div className='d-flex profile-header-back'>
                                <Link to='/my-profile'>
                                    <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                        <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                    </span>
                                </Link>
                            </div>
                            <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Mon profil</div>
                        </div>

                        <div className='d-flex flex-column profile-block'>
                            <div className='user-title d-flex justify-content-center'>
                                Mes enfants
                            </div>

                            <div className='d-flex flex-column align-items-center'>
                                <span><em>Liste des enfants</em> { childrenList.length > 1 && <Link to='/delete-child'><img style={{height: '24px', marginLeft: '50px'}} alt="trash" src="/images/trash.png"></img></Link>}</span>
                                { childrenList.length > 0 ?
                                    childrenList.map((child) => (
                                        <div role='presentation' onClick={child.default === true ? null : () => changeChildren(child.id)} key={child.id} className={child.default === true ? 'user-canteen selected' : 'user-canteen' }>
                                            <p className='d-flex align-items-center justify-content-between'>
                                                <span>{child.name}</span>

                                                <span>
                                                    {child.default &&
                                                        <i className='default-canteen fa-solid fa-circle-check'></i>
                                                    }
                                                    <i role='presentation' onClick={(event) => updateChildren(child.id, event)} className='ms-2 unsolds-card-infos fa-solid fa-pencil'></i>
                                                </span>
                                            </p>
                                        </div>
                                    )) :
                                    <span>Vous n&apos;avez pas encore d&apos;enfant</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-end flex-column justify-content-center align-items-center'>
                        <span>Code Famille</span>
                        <div className='user-canteen'>
                            <p className='d-flex align-items-center justify-content-between'>
                                <span>{codeFamily}</span>
                                <span><i role='presentation' onClick={() => copyToClipboard(codeFamily)} className='ms-2 unsolds-card-infos fa-solid fa-copy'></i></span>
                            </p>
                        </div>
                        <button className='user-submit my-4'>
                            <Link className='d-block' to='/add-child'>Ajouter un enfant</Link>
                        </button>
                    </div>
                </div>
            }
        </>
    )
}

export default UserFamily;
