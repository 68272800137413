import { useCookies } from 'react-cookie';
import axios from '../api/axios';
import useAuth from './useAuth';
import useLogout from './useLogout';

const useRefreshToken = () => {
    // Global
    const { auth, setAuth } = useAuth();
    const [cookie, setCookie] = useCookies();
    const logout = useLogout();

    // Refresh Token
    return async () => {
        try {
            const response = await axios.post('/users/token/refresh', {
                refresh_token: auth?.refreshToken,
            }, {
                withCredentials: true,
            });

            setAuth(prev => {
                return {...prev, token: response.data.token}
            });

            cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['token'] = response.data.token;
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`], { path: '/' });

            return response.data.token;
        } catch (err) {
            logout();

            return null;
        }
    };
};

export default useRefreshToken;
