import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import MealCalendar from '../../components/Meal/MealCalendar';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import MenuFooter from '../../components/MenuFooter';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';

const MealReview = () => {
    const { setFlash, setShowFlash } = useAuth();
    const param = useParams()
    const { setIsLoading } = useAuth();
    const [calendarDisplay, setCalendarDisplay] = useState(false);
    const [mealsDisplay, setMealsDisplay] = useState(false);
    const [meals, setMeals] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [reservations, setReservations] = useState([])
    const [reviewBlock, setReviewBlock] = useState();
    const [review, setReview] = useState();
    const [cookie, setCookie] = useCookies();
    const navigate = useNavigate();

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#EE4447');

    const getCanteenInformations = async () => {
        try {     
            const result = await axiosPrivate.get('/guests/canteen', { withCredentials: true })

            if( result.data.canteen.unsoldActivated ){
                //setDisplayUnsolds(true)
            }
        } catch (error) {
            console.log(error)
        }
    };

    let params = useParams()
    let currentDate = params.date

    const getReservations = async () => {
        setIsLoading(true);
        try {     
            const result = await axiosPrivate.get(`/guests/reservation/meal?date=${currentDate}`, { withCredentials: true })
            const reservationsData = result.data['dates']
            setReservations(reservationsData)

            setCalendarDisplay(true)
            setIsLoading(false);
        } catch (error) {
            console.log(error)
        }
    };

    const getMeals = async () => {
        try {     
            const result = await axiosPrivate.get(`/guests/canteen/menu?date=${currentDate}`, { withCredentials: true })
            setMeals(result.data)
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_menuId`, result.data.infos.menu_id, { path: '/' });
            return result.data.infos.menu_id
        } catch (error) {
            console.log(error)     
        }
        setIsLoading(false);
        setMealsDisplay(true);
    };

    const getReview = async (menuId) => {
        try {     
            // const result = await axiosPrivate.get(`/guests/review/menu?date=${currentDate}`, { withCredentials: true })
            // setMeals(result.data)
            const result = await axiosPrivate.get(`/guests/canteen/review?canteenMenuId=${menuId}`, { withCredentials: true })

            setReview({
                note: result.data.note,
                comments: result.data.comments
            })
        } catch (error) {
            console.log(error)      
        }
    }

    useEffect(() => {
        setMealsDisplay(false)
        setIsLoading(true)
        getCanteenInformations()
        getReservations().then(() => getMeals()).then((menuId) => getUserReservations(menuId)).then((menuId) => getReview(menuId));
    }, [param.date]);

    const votePlate = async (mealId, note) => {
        for (let i = 1; i <= note; i++) {
            document.querySelector(`.review-star[data-meal-id="${mealId}"][data-note="${i}"]`).src = '/images/star-icon.png'
        }

        for (let i = note + 1; i <= 5; i++) {
            document.querySelector(`.review-star[data-meal-id="${mealId}"][data-note="${i}"]`).src = '/images/star-empty-icon.png'
        }

        await axiosPrivate.post('/guests/canteen/review_meal', {mealId: mealId, note: note}, { withCredentials: true })
    };

    const getUserReservations = async (menuId) => {
        setIsLoading(true);
        setReviewBlock(
            <div className='review-title'>Gagnez plus de points lors de votre passage à la cantine en sélectionnant votre plat à l’avance !</div>
        )

        if( undefined !== menuId ){
            try {
                const result = await axiosPrivate.get(`/guests/reservation/menu/meal?menu_id=${menuId}`, { withCredentials: true })
    
                if( result.data.mealsInformations.length > 0 ){
                    const block = result.data.mealsInformations.map((meal, key) =>
                        <div className='d-flex w-100' key={key}>
                            <div className='review-meal'>{meal.name}</div>
                            <div className='d-flex align-items-center'>
                                <img role='presentation' data-note={1} data-meal-id={meal.id} onClick={() => votePlate(meal.id, 1)} className='review-star' src={meal.note >= 1 ? '/images/star-icon.png' : '/images/star-empty-icon.png'} alt='1 étoile'></img>
                                <img role='presentation' data-note={2} data-meal-id={meal.id} onClick={() => votePlate(meal.id, 2)} className='review-star' src={meal.note >= 2 ? '/images/star-icon.png' : '/images/star-empty-icon.png'} alt='2 étoiles'></img>
                                <img role='presentation' data-note={3} data-meal-id={meal.id} onClick={() => votePlate(meal.id, 3)} className='review-star' src={meal.note >= 3 ? '/images/star-icon.png' : '/images/star-empty-icon.png'} alt='3 étoiles'></img>
                                <img role='presentation' data-note={4} data-meal-id={meal.id} onClick={() => votePlate(meal.id, 4)} className='review-star' src={meal.note >= 4 ? '/images/star-icon.png' : '/images/star-empty-icon.png'} alt='4 étoiles'></img>
                                <img role='presentation' data-note={5} data-meal-id={meal.id} onClick={() => votePlate(meal.id, 5)} className='review-star' src={meal.note >= 5 ? '/images/star-icon.png' : '/images/star-empty-icon.png'} alt='5 étoiles'></img>
                            </div>
                        </div>
                    )

                    setReviewBlock( 
                        <div className='review-title'>{block}</div>
                    )
                }
            } catch (error) {
                console.log(error);
            }
        }
        
        setIsLoading(false);
        setMealsDisplay(true);

        return menuId
    };

    const unsolds = {
        remains: 26,
        meals: [
            {name: 'Panier volaille', price: 3, remain: 4, labels:[]},
            {name: 'Panier viande', price: 3, remain: 7, labels:[]},
            {name: 'Panier poisson', price: 3, remain: 6, labels:['local']},
            {name: 'Panier végétarien', price: 3, remain: 9, labels:[]},
        ]
    }

    const vote = async (event) => {
        document.querySelectorAll('.review-vote').forEach((imageElement) => {
            imageElement.classList.remove('review-vote-chosen')
            imageElement.classList.add('review-vote-not-chosen')
        })

        event.target.classList.add('review-vote-chosen')
    };

    const confirmVote = async () => {
        setShowFlash(false);
        setFlash({});

        await axiosPrivate.post('/guests/canteen/review', {
            canteenMenuId: cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_menuId`], 
            note: document.querySelector('.review-vote-chosen').dataset.value, 
            comments: document.querySelector('.review-comments').value
        }, { withCredentials: true })
        setFlash({message: 'Merci d\'avoir donné votre avis', type: 'success'});
        setShowFlash(true);
        navigate('/')
    };

    return ( mealsDisplay && 
        <div className='home-page d-flex min-vh-100 justify-content-center'>
            <input type='hidden' id='menuId'></input>
            <div className='meal-header'>
                <ProfileHeader bg='calendar_reservation' canteenName={meals?.infos?.canteen_name} page='home'></ProfileHeader>
                <MealCalendar calendarDisplay={calendarDisplay} reservations={reservations} currentDate={currentDate}></MealCalendar>
            </div>

            <div role='presentation' id='review-infos' className='d-flex align-items-center flex-grow-1 flex-shrink-1 w-100'>
                <div className='review-headtitle text-center'>Comment avez-vous trouvé le repas d’hier ?</div>
                <div className='review-votes d-flex w-100'>
                    <div className='d-flex m-2 justify-content-center align-items-center flex-grow-1 flex-shrink-1'><img data-value={1} role='presentation' onClick={(event) => vote(event)} className={`review-vote ${review?.note == 1 ? 'review-vote-chosen' : 'review-vote-not-chosen'}`} src='/images/bad-review-icon.png' alt='Bad'></img></div>
                    <div className='d-flex m-2 justify-content-center align-items-center flex-grow-1 flex-shrink-1'><img data-value={3} role='presentation' onClick={(event) => vote(event)} className={`review-vote ${review?.note == 3 ? 'review-vote-chosen' : 'review-vote-not-chosen'}`} src='/images/neutral-review-icon.png' alt='Neutral'></img></div>
                    <div className='d-flex m-2 justify-content-center align-items-center flex-grow-1 flex-shrink-1'><img data-value={5} role='presentation' onClick={(event) => vote(event)} className={`review-vote ${review?.note == 5 ? 'review-vote-chosen' : 'review-vote-not-chosen'}`} src='/images/good-review-icon.png' alt='Good'></img></div>
                </div>

                {reviewBlock}

                <div>
                    <textarea className='review-comments' placeholder='Commentaire ?' defaultValue={review?.comments}></textarea>
                </div>

                <div className='confirm-meal'>
                    <button onClick={confirmVote} type="submit" className='review-submit border-0'>Valider</button>
                </div>
            </div>

            <MenuFooter unsoldsRemain={unsolds.remains}></MenuFooter>
        </div>
    )
};

export default MealReview;
