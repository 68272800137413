import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

const Activate = () => {
    // Global
    const navigate = useNavigate();
    const { email, token } = useParams();
    const { setFlash, setShowFlash, setIsLoading } = useAuth();

    // Check User / Token
    const checkToken = async() => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            let url = `/users/activate/token?email=${email}&token=${token}`;
            await axios.get(url, { withCredentials: true }).then(() => {
                return axios.put('/users/activate', {
                    email: email,
                    token: token,
                }, { withCredentials: true });
            });
            setFlash({ message: 'Compte Activé.', type: 'success' });
            navigate('/login');
        } catch (err) {
            let error;
            let redirect = '/login';
            if (err?.response?.status === 400) {
                error = 'Le Token est expiré';
            } else if (err?.response?.status === 404) {
                error = 'Le Token est incorrect';
            } else {
                error = 'Erreur Serveur';
                redirect = '/500';
            }
            setFlash({ message: error });
            navigate(redirect);
        }

        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        checkToken();
    }, [])
};

export default Activate;
