import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorTemplate = ({ h1 }) => {
    // Global
    const navigate = useNavigate();

    // Back Page
    const back = () => navigate(-1);

    return (
        <div className="d-flex">
            <div className="d-flex col-12 col-md-7 justify-content-center align-items-center flex-column vh-100">
                <span>
                    <img className='mw-100' alt="" src="/logo.png"></img>
                </span>

                <h1>{ h1 }</h1>
                <button className="btn btn-secondary" onClick={ back }>Retour</button>
            </div>

            <div className="d-none d-sm-flex col-md-5 d-flex-end justify-content-end">
                <img className='vh-100' alt="" src="/images/login.png"></img>
            </div>
        </div>
    );
};

export default ErrorTemplate;
