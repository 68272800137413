import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAuth from '../hooks/useAuth';

const Tutorial = ({step}) => {
    const [cookie, setCookie, removeCookie] = useCookies({});
    const { auth, setAuth } = useAuth();

    let nextLink = `/tutorial/${step + 1}`
    let previousButton = '', nextButton = '', title = '', content = '', tips = ''//, image = ''
    let step1 = 'step-inactive', step2 = 'step-inactive', step3 = 'step-inactive', step4 = 'step-inactive', step5 = 'step-inactive'

    if( 1 === step ){
        nextButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-right'></i>
        title = 'L’application qui t’aide à mieux manger et moins jeter !'
        content = ''
        step1 = 'step-active'
        // image = 'tutorial-1.png'
    }

    if( 2 === step ){
        previousButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-left'></i>
        nextButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-right'></i>
        title = 'Créer ton profil joueur'
        content = 'Créer un avatar et choisis un profil afin de pouvoir rejoindre la compétition !'
        step2 = 'step-active'
        // image = 'tutorial-2.png'
    }

    if( 3 === step ){
        previousButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-left'></i>
        nextButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-right'></i>
        title = 'Vote pour gagner un max de points '
        content = 'Choisis chaque jour tes plats favoris jusqu’à 4 semaines à l\'avance.'
        tips = 'vote le plus tôt possible et / ou enchaine plusieurs votes d’affilées pour multiplier tes points !'
        step3 = 'step-active'
        // image = 'tutorial-2.png'
    }

    if( 4 === step ){
        previousButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-left'></i>
        nextButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-right'></i>
        title = 'Compétition par équipe'
        content = 'Crée ou rejoins une équipe pour participer à la compétition avec tes amis. Chaque équipe peut accueillir entre 2 et 10 joueurs !'
        step4 = 'step-active'
        // image = 'tutorial-3.png'
    }

    if( 5 === step ){
        nextLink = '/home'
        previousButton = <i className='tutorial-nav-button fa-solid fa-circle-chevron-left'></i>
        nextButton = <span className='tutorial-go'>Go</span>
        title = 'Ton avis compte'
        content = 'Après chaque repas, donne ton avis pour collecter des points supplémentaires !'
        step5 = 'step-active'
        // image = 'tutorial-3.png'
    }

    useEffect(() => {
        let children = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_children`] ?? 'undefined';
        if (children !== 'undefined') {
            setAuth({ ...auth, childId: children.default.id });
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, childId: children.default.id }, { path: '/' })
            setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {'id': children.default.id, 'name': children.default.name, 'point': children.default.point}, { path: '/' })
            removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_children`, {path: '/'});
        }
    }, []);

    const handleClick = ()=> {
        setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, false, {path: '/'})
    }

    return (
        <>
            <div className='d-flex justify-content-center'>
                <img style={{width:'200px'}} src='/images/logo.png' alt='Jabu'></img>
            </div>

            {/* <div className='tutorial-image'>
                <img className='w-100' src={`/images/${image}`} alt='Jabu'></img>
            </div> */}

            {/* <div className='tutorial-skip'>
                { step >= 2 &&
                    <Link to='/' onClick={handleClick}><span>Ignorer</span></Link>
                }
            </div> */}

            <div className='tutorial-title'>
                Bienvenue sur Jabu !
            </div>

            <div className='tutorial-subtitle'>
                {title}
            </div>

            <div className='tutorial-content flex-column'>
                {content}
            </div>

            { tips != '' && 
                <div className='tutorial-tips'>
                    <div className='d-flex align-items-center'><i style={{'fontSize': '24px', 'marginRight': '20px'}} className='fa-regular fa-lightbulb'></i></div>
                    <div className='d-flex'>
                        <div>
                            <b>Conseil : </b>
                            {tips}
                        </div>
                    </div>
                </div>
            }

            <div className='tutorial-navigation'>
                <div className='tutorial-navigation-button'>
                    <Link to={`/tutorial/${step - 1}`}>{previousButton}</Link>
                </div>

                <div className='d-flex flex-grow-1 justify-content-center'>
                    <i className={`fa-solid fa-minus ${step1}`}></i>
                    <i className={`fa-solid fa-minus ${step2}`}></i>
                    <i className={`fa-solid fa-minus ${step3}`}></i>
                    <i className={`fa-solid fa-minus ${step4}`}></i>
                    <i className={`fa-solid fa-minus ${step5}`}></i>
                </div>

                <div className='tutorial-navigation-button'>
                    { step === 3 ? (
                        <Link to={nextLink} onClick={handleClick}>{nextButton}</Link>
                    ) : (
                        <Link to={nextLink}>{nextButton}</Link>
                    )}
                </div>
            </div>
        </>
    )
}

export default Tutorial
