import React, { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';

// Context Authentication
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [cookie] = useCookies(['user']);
    // Data Cookie
    
    let userCookie = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]
    let username = userCookie?.username;
    let lastName = userCookie?.lastName;
    let firstName = userCookie?.firstName;
    let roles = userCookie?.roles;
    let token = userCookie?.token;
    let refreshToken = userCookie?.refreshToken;
    let jabuPoint = userCookie?.jabuPoint;
    let canteenId = userCookie?.canteenId;
    let childId = userCookie?.childId;
    let team = userCookie?.team;

    // Init Context Auth (Reload Auth Provider with Cookie)
    const [auth, setAuth] = useState({username, lastName, firstName, roles, token, refreshToken, jabuPoint, canteenId, childId, team});

    // Loader
    const [isLoading, setIsLoading] = useState(false);

    // Flash Message
    const [flash, setFlash] = useState({});
    const [showFlash, setShowFlash] = useState(false);

    // Modal
    const [modal, setModal] = useState({});
    const [showModal, setShowModal] = useState(false);

    return (
        <AuthContext.Provider value={{ auth, setAuth, flash, setFlash, showFlash, setShowFlash, isLoading, setIsLoading, modal, setModal, showModal, setShowModal }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
