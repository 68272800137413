import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const ProfileSection = ({icon, name, route = 'my-profile', info = null}) => {
    const { setModal, setShowModal } = useAuth();
    const navigate = useNavigate();

    const logout = async () => {
        setShowModal(false)
        navigate('/logout')
    }

    const confirm = async () => {
        setModal({content: 
            <div className='d-flex flex-column align-items-center p-4'>
                <div className='logout-confirm-title'>Confirmer la déconnexion</div>
                <div role='presentation' className='logout-confirm' onClick={() => logout()}>Valider</div>
                <div role='presentation' className='logout-cancel' onClick={() => setShowModal(false)}>Annuler</div>
            </div>
        })
        setShowModal(true)
    }

    let iconBlock = null
    if( icon != undefined ){
        iconBlock = <img src={`/images/${icon}-icon.png`} alt={icon}></img>
    }
    
    if( 'logout' == route ){
        return (
            <div role='presentation' className='profile-section profile-section-link' onClick={() => confirm()}>
                <div className='profile-section-icon'>
                    {iconBlock}
                </div>
                
                <div className='d-flex flex-column flex-grow-1'>
                    <div className='profile-section-name'>{name}</div>

                    {
                        (null !== info)
                            ?
                            <div className='profile-section-info'>{info}</div>
                            :
                            null
                    }
                </div>

                <div className='d-flex flex-end profile-section-back'>
                    <i style={{color: '#BBB'}} className='fa-solid fa-chevron-right'></i>
                </div>
            </div>
        )
    }else{
        return (
            <>
                <Link to={`/${route}`}>
                    <div className='profile-section'>
                        <div className='profile-section-icon'>
                            {iconBlock}
                        </div>
                        
                        <div className='d-flex flex-column flex-grow-1'>
                            <div className='profile-section-name'>{name}</div>

                            {
                                (null !== info)
                                    ?
                                    <div className='profile-section-info'>{info}</div>
                                    :
                                    null
                            }
                        </div>

                        <div className='d-flex flex-end profile-section-back'>
                            <i style={{color: '#BBB'}} className='fa-solid fa-chevron-right'></i>
                        </div>
                    </div>
                </Link>
            </>
        )
    }
}

export default ProfileSection
