import React from 'react';
import { Link } from 'react-router-dom';

const HelperPage = () => {
    return (
        <div className="profil-page d-flex helper min-vh-100 justify-content-center">
            <div className="d-flex flex-column align-items-center flex-grow-1 flex-shrink-1">
                <div className="profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center">
                    <div className="d-flex profile-header-back">
                        <Link to='/my-profile'>
                            <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                            </span>
                        </Link>
                    </div>
                    <div className="d-flex justify-content-center flex-grow-1 profile-header-text">Mon profil</div>
                </div>

                <div className="d-flex flex-column profile-block">
                    <div className="user-title d-flex justify-content-center">
                        Aide et support
                    </div>

                    <div className="d-flex flex-column align-items-center">
                        <p className='question'>Pourquoi Jabu ?</p>
                        <p className='answer'>Jabu est conçu pour transformer la restauration collective en optimisant l&apos;anticipation des besoins, réduisant le gaspillage alimentaire et améliorant l&apos;expérience des convives. En utilisant Jabu, vous aidez le chef à préparer la juste quantité de nourriture, tout en maximisant vos chances de déguster vos plats préférés.</p>

                        <p className='question'>1. Voter pour un plat</p>
                        <p className='answer'>Votez pour vos plats favoris à l&apos;avance sur la page des menus de l&apos;application. Cette action simple nous aide à mieux prédire les quantités nécessaires, réduisant ainsi le gaspillage et maximisant les chances de retrouver ce que vous aimez.</p>

                        <p className='question'>2. Si le menu n&apos;apparaît pas</p>
                        <p className='answer'>Le menu peut être en cours de préparation ou pas encore publié. N&apos;hésitez pas à revenir plus tard ou nous contacter.</p>
                        
                        <p className='question'>3. Acheter des paniers invendus</p>
                        <p className='answer'>Visitez l&apos;onglet &quot;Invendus&quot; pour acheter des plats à prix réduit. Ces paniers anti-gaspi sont une excellente manière de savourer de bons plats tout en agissant pour l&apos;environnement.</p>

                        <p className='question'>4. Assistance technique</p>
                        <p className='answer'>Pour toute difficulté technique ou question sur l&apos;utilisation de Jabu, notre équipe est là pour vous aider. Envoyez-nous un email à <a className='link' href='mailto:support@jabu-app.com?subject=Demande de support JABU'>support@jabu-app.com</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelperPage;
