import React from 'react';
import MealSection from './MealSection';

const MealMenu = ({ userAllergens=null, userDietaryConstraints=null, getValues, setValue, mealsDisplay, meals, total, setTotal, register, expired=false }) => {
    let cheeseSection = ''
    if( 'cheeses' in meals ){
        cheeseSection = 
            <MealSection expired={expired} getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} category='cheese' name='Laitages' meals={meals.cheeses ?? []}></MealSection>
    }

    if( false == expired ){
        return (
            <>
                { mealsDisplay &&
                    <div className='meal-choose d-flex flex-column justify-content-center flex-grow-1 flex-shrink-1'>
                        <MealSection getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} category='entry' name='Entrées' icon='carrot' meals={meals.platters ?? []}></MealSection>
                        <MealSection userAllergens={userAllergens} userDietaryConstraints={userDietaryConstraints} getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} category='meal' name='Plats' icon='bell-concierge' meals={meals.meals ?? []}></MealSection>
                        <MealSection getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} category='accompagniments' name='Accompagnements' icon='bell-concierge' meals={meals.accompaniments ?? []}></MealSection>
                        {cheeseSection}
                        <MealSection getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} category='dessert' name='Desserts' icon='ice-cream' meals={meals.desserts ?? []}></MealSection>
                    </div>
                }
            </>
        )
    }else{
        return (
            <>
                { mealsDisplay &&
                    <div className='meal-choose d-flex flex-column justify-content-center flex-grow-1 flex-shrink-1' style={{margin:0}}>
                        <div className="meal-section attendance-section" style={{justifyContent: 'center', marginTop: 0}}>
                            <span className="expired-meal-section-name">Menus du jour</span>
                        </div>

                        <MealSection expired={expired} getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} category='entry' name='Entrées' icon='carrot' meals={meals.platters ?? []}></MealSection>
                        <MealSection expired={expired} userAllergens={userAllergens} userDietaryConstraints={userDietaryConstraints} getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} category='meal' name='Plats' icon='bell-concierge' meals={meals.meals ?? []}></MealSection>
                        <MealSection expired={expired} getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} category='accompagniments' name='Accompagnements' icon='bell-concierge' meals={meals.accompaniments ?? []}></MealSection>
                        {cheeseSection}
                        <MealSection expired={expired} getValues={getValues} setValue={setValue} register={register} total={total} setTotal={setTotal} category='dessert' name='Desserts' icon='ice-cream' meals={meals.desserts ?? []}></MealSection>
                    </div>
                }
            </>
        )
    }
}

export default MealMenu
