import React, { useEffect } from 'react';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import MenuFooter from '../../components/MenuFooter';
import useQRCodeScan from '../../hooks/useQRCodeScan';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';
import useAuth from '../../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';

const QRCodePresence = () => {
    const axiosPrivate = useAxiosPrivate()
    const [startQrCode, decodedQRData] = useQRCodeScan();
    const { auth, setModal, setShowModal } = useAuth();
    const [cookie, setCookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_user`]);
    const canteenName = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['name']
    const completeDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    const completeMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    const today = new Date()
    const navigate = useNavigate()

    const hideModal = () => {
        setShowModal(false)
    }

    useEffect(() => {
        const onPageLoad = () => {
            startQrCode()
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);


    useEffect(() => {
        // Set QR Code Data if Scan Success
        if (decodedQRData?.data) {
            axiosPrivate.put('/guests/reservation/here', {canteen_code: decodedQRData?.data}, { withCredentials: true }).then((response) => {
                let username = auth.username
                let lastName = auth.lastName
                let firstName = auth.firstName
                let roles = auth.roles
                let token = auth.token
                let refreshToken = auth.refreshToken
                let jabuPoint = response.data.points

                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { username, lastName, firstName, roles, token, refreshToken, jabuPoint }, { path: '/' });
                return response
            }).then((response) => {
                navigate('/')
                if( true == response.data.reserved && true == response.data.here ){
                    setModal({
                        title: 
                            <div className='comfirm-presence-title comfirm-presence-ok-title'>Passage validé, bon appétit !</div>,
                        content: 
                            <div className='confirm-presence'>
                                <div className='presence-complete-day'>{ completeDays[today.getDay()] } {String(today.getDate()).padStart(2, '0')} { completeMonths[today.getMonth()] }</div>
                                <div className='presence-message presence-message-ok'>Votre compte Jabu a été crédité de<div className='points-claimed'>10pts</div></div>
                                <button onClick={hideModal} className='presence-continue presence-ok-continue'>Continuer</button>
        
                                <Link to={'/my-points'}>
                                    <button onClick={hideModal} className='presence-ok-see-points see-points'>Consulter mon solde Jabu</button>
                                </Link>
                            </div>
                    })
                }else{
                    setModal({
                        title: 
                            <div className='comfirm-presence-title comfirm-presence-ko-title'>Repas non réservé</div>,
                        content: 
                            <div className='confirm-presence'>
                                <div className='presence-complete-day'>{ completeDays[today.getDay()] } {String(today.getDate()).padStart(2, '0')} { completeMonths[today.getMonth()] }</div>
                                <div className='presence-message presence-message-ko'>Pensez a réserver votre repas 72h à l’avance pour nous aider à limiter le gaspillage et cumuler des points Jabu !</div>
                                <button onClick={hideModal} className='presence-continue presence-ko-continue'>Continuer</button>
        
                                <Link to={'/my-points'}>
                                    <button onClick={hideModal} className='presence-ko-see-points see-points'>Consulter mon solde Jabu</button>
                                </Link>
                            </div>
                    })
                }
                
                setShowModal(true)
            })
        }
    }, [decodedQRData]);

    return (
        <div className='home min-vh-100'>
            <div className='meal-header'>
                <ProfileHeader bg='calendar_reservation' canteenName={canteenName} page='qr-code'></ProfileHeader>
            </div>

            <div id='news-infos'>
                <div className='qrcode-resa' id='qrcode-scan'></div>
                <div className='qr-code-label'>Flasher le QR Code</div>
            </div>

            <MenuFooter></MenuFooter>
        </div>
    );
};

export default QRCodePresence;
