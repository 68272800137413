import React from 'react';
// import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const ProfileHeader = ({ bg}) => {
    // Provider
    const [cookies] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_user`]);
    const userFirstName = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['firstName'] ?? ' '
    const userLastName = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['lastName'] ?? ' '

    let userPoints;
    if ('undefined' !== cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]){
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['point'] ?? 0
    } else {
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint'] ?? 0
    }

    return ( cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`] &&
        <>
            <div className={`${bg} d-flex flex-column w-100`}>
                <div className='d-flex p-1'>
                    <Link to='/my-profile' className='col-6 d-flex align-items-center'>
                        <div className='avatar-name-block'>
                            <span className='avatar-name'>{userFirstName.slice(0,1)}{userLastName.slice(0,1)}</span>
                        </div>

                        <div className='d-flex' style={{'marginLeft': '20px'}}>
                            {userFirstName}
                        </div>
                    </Link>

                    <div className='header-qr-code col-6 d-flex justify-content-end align-items-center'>
                        <div className='header-points'>
                            <img style={{width: '40px'}} src='/images/points-icon.png' alt='points'></img>
                            <span className='avatar-name'>{userPoints}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileHeader
