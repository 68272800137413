import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';
import jwt_decode from 'jwt-decode';

const CallBackRegisterPage = () => {
    // Global
    const navigate = useNavigate();
    const { auth, setIsLoading, setFlash, setShowFlash } = useAuth();
    const { type } = useParams();

    // Check User / Token
    const getToken = async() => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        await axios.get(`/users/token/social/get${window.location.search}&type=${type}`)
            .then(response => {
                // Data
                let token = response.data.token;
                let jwtDecode = jwt_decode(token);
                axios.post('/users/register', {
                    gender: null,
                    last_name: jwtDecode?.family_name ?? '',
                    first_name: jwtDecode?.given_name ?? '',
                    email: jwtDecode?.email ?? '',
                    password: '',
                    sub: jwtDecode.sub,
                    type: type,
                    is_chef: false,
                }, { withCredentials: true }).then(() => {
                    return axios.post('/users/activate/token', {
                        email: jwtDecode?.email,
                    }, {withCredentials: true})
                });
                setFlash({ message: 'Compte Créé avec succès. Un Email a été envoyé pour activer votre compte', type: 'success' });
                navigate('/login');
            }).catch(() => {
                setFlash({ message: 'Utilisateur déjà existant' });
                setShowFlash(true);
                navigate('/register');
            });

        // Flash Message
        setShowFlash(true);
        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        // Redirect if connected
        if (auth?.username) {
            navigate('/');
        } else {
            getToken();
        }
    }, [])
};

export default CallBackRegisterPage;
