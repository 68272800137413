import React, {useEffect, useState} from 'react';
import { useCookies } from 'react-cookie';
import MenuFooter from '../../components/MenuFooter';
// import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import dayjs from 'dayjs';


const ChallengePage = () => {
    // const [cookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]);
    const axiosPrivate = useAxiosPrivate();
    const [cookies] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_user`]);
    const dayShortNames = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
    const [reviewsDisplay, setReviewsDisplay] = useState(false);
    const [, setReviews] = useState([]);
    const { setModal, setShowModal, setFlash, setShowFlash } = useAuth();
    const [teamDisplay, setTeamDisplay] = useState(false);
    const [teamates, setTeamates] = useState([]);
    const [topUsers, setTopUsers] = useState([]);
    const [topTeams, setTopTeams] = useState([]);
    const [, setTeamName] = useState('');
    const [, setTeamAvatar] = useState('');
    const [teamCode, setTeamCode] = useState('');
    const [teamOwner, setTeamOwner] = useState(false);
    // const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id']
    // const axiosPrivate = useAxiosPrivate();

    const getTeam = async () => {
        try {     
            const result = await axiosPrivate.get('/guests/team/members')
            setTeamates(result.data.members)
            setTeamName(result.data.teamId)
            setTeamAvatar(result.data.teamAvatar)
            setTeamCode(result.data.teamCode)
            setTeamOwner(result.data.teamOwner)
        } catch (error) {
            console.log(error)
        }
        setTeamDisplay(true)
    }

    const getTopTeams = async () => {
        try {     
            const result = await axiosPrivate.get('/guests/team/ranking', { withCredentials: true })
            setTopTeams(result.data.teams)
        } catch (error) {
            console.log(error)
        }
    }

    const getTopUsers = async () => {
        try {     
            const result = await axiosPrivate.get('/guests/top-ranking', { withCredentials: true })
            setTopUsers(result.data.users)
        } catch (error) {
            console.log(error)
        }
    }

    let userPoints;
    if ('undefined' !== cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]){
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['point'] ?? 0
    } else {
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint'] ?? 0
    }

    const today = new Date()
    const currentDate = today.toJSON().slice(0, 10)
    let weekDates = []
    let weekDays = []
    for (let i = 1; i <= 5; i++) {
        let closed = false
        let first = today.getDate() - today.getDay() + i 
        let day = new Date(today.setDate(first)).toISOString().slice(0, 10)

        if( 3 == i ){
            closed = true
        }

        weekDates.push(day)
        weekDays.push({name: dayShortNames[i-1], day: day.slice(-2), date: day, closed: closed})
    }

    const copyTeamCode = (teamCode) => {
        navigator.clipboard.writeText(teamCode);
        setFlash({ message: 'Code Copié', type: 'success' });
        setShowFlash(true);
    }

    const deleteTeam = () => {
        axiosPrivate.delete('/guests/team', {data:{}, withCredentials: true }).then(() => {
            setShowModal(false)
            location.reload()
        })
    }

    const leaveTeam = () => {
        axiosPrivate.delete('/guests/team/member', {data:{}, withCredentials: true }).then(() => {
            setShowModal(false)
            location.reload()
        })
    }

    const showManageTeam = () => {
        setModal({
            title: <div className='d-flex justify-content-center align-items-center py-2 mb-3'>Gestion de l&apos;équipe</div>,
            content: <div style={{margin: '0 10px 10px 10px'}}>
                <div className='review-answer' role='presentation' onClick={() => copyTeamCode(teamCode)}>Copier le code équipe - {teamCode}</div>
                {teamOwner && <div className='review-answer' role='presentation' onClick={deleteTeam}>Supprimer mon équipe</div>}
                {!teamOwner && <div  className='review-answer' role='presentation' onClick={leaveTeam}>Quitter mon équipe</div>}
            </div>
        })
        setShowModal(true)
    }

    const getReviews = async (weekDates) => {
        try {    
            const result = await axiosPrivate.get(`/guests/meal/week/review?dates=${weekDates.join(',')}`, { withCredentials: true })
            setReviews(result.data.reviews)
            setReviewsDisplay(true)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getTopTeams()
        getTopUsers()
        getTeam()
        getReviews(weekDates)
    }, [])
    
    let votes = []
    // reviews.forEach((review, date) => {
    //     if(null !== review){
    //         votes.push(date)
    //     }
    // })

    weekDays.forEach((weekDay) => {
        weekDay.voting = false
        weekDay.future = false
        if( votes.includes(weekDay.date) ){
            weekDay.voting = true
        }
        if( weekDay.date >= currentDate ){
            weekDay.voting = null
            weekDay.future = true
        }

        return weekDay
    })

    const challengeWeek = weekDays.map((weekDay) => {
        let votingClass = ''
        let weekDayClass = ''

        if( true == weekDay.voting ){
            votingClass = 'voting'
        }

        if( false == weekDay.voting ){
            votingClass = 'not-voting'
        }

        if( null == weekDay.voting ){
            votingClass = 'no-voting'
        }

        if( weekDay.future ){
            weekDayClass = 'future-day'
        }

        if( currentDate == weekDay.date ){
            weekDayClass = 'current-day'
        }

        return <div key={weekDay.day} className={`challenge-week-day ${weekDayClass}`}>
            <div className={votingClass}><i className="fa-solid fa-circle"></i></div>
            <div className='dayNumber'>{weekDay.day}</div>
            <div className='dayWeek'>{weekDay.name}</div>
        </div>
    })

    let currentDay = new Date(currentDate)
    const completeDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    const completeMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    let current = dayjs(currentDate);
    const dateBefore = () => {
        current = current.add(-1, 'day')
        displayReviewFrom(current.format('YYYY-MM-DD'))
    };
    const dateAfter= () => {
        current = current.add(1, 'day')
        displayReviewFrom(current.format('YYYY-MM-DD'))
    };

    const saveAndGo = (date, pageNumber, answer)=> {
        if( 2 == pageNumber ){
            //Create review
            axiosPrivate.post('/guests/meal/review', {
                canteenDishId: answer,
                canteenDishOriginId: answer,
                dateMeal: current.format('YYYY-MM-DD'),
                isGoodVisual: null,
                isGoodQuantity: null,
                isGoodTaste: null,
                comment: null,
            }, {withCredentials: true}).then(() => {
                axiosPrivate.get(`/guests/meal/review?date=${date}`, {withCredentials: true}).then((response) => {
                    let review = response?.data?.mealReview
                    let answerYesChosen = ''
                    let answerNoChosen = ''
                    if(true == review?.isGoodVisual){
                        answerYesChosen = 'review-answer-chosen'
                    }

                    if(false == review?.isGoodVisual){
                        answerNoChosen = 'review-answer-chosen'
                    }

                    setModal({content: <div style={{padding: '20px 10px'}}>
                        <div className='meal-header' style={{position: 'relative', backgroundColor: 'white'}}>
                            <div className='d-flex'>
                                <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                            </div>
                            
                            <div className='presence-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                            
                            <div className='d-flex'>
                                <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                            </div>
                        </div>
                    
                        <div className='d-flex' style={{marginTop: '10px'}}>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step review-step-active'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                        </div>
            
                        <div className='review-modal-title'>
                            Question <span id="modal-question-number">2</span> sur 5
                        </div>
            
                        <div className='review-question'>
                            J’ai apprecié le visuel de mon plat
                        </div>
            
                        <div className='review-answers'>
                            <div role='presentation' className={`review-answer ${answerYesChosen}`} onClick={() => saveAndGo(date, 3, 1)}>Oui</div>
                            <div role='presentation' className={`review-answer ${answerNoChosen}`} onClick={() => saveAndGo(date, 3, 0)}>Non</div>
                        </div>
                    </div>})
                })
            })
        }

        if( 3 == pageNumber ){
            axiosPrivate.put('/guests/meal/review', {
                dateMeal: current.format('YYYY-MM-DD'),
                isGoodVisual: (1 == answer),
            }, {withCredentials: true}).then(() => {
                axiosPrivate.get(`/guests/meal/review?date=${date}`, {withCredentials: true}).then((response) => {
                    let review = response?.data?.mealReview
                    let answerYesChosen = ''
                    let answerNoChosen = ''
                    if(true == review?.isGoodVisual){
                        answerYesChosen = 'review-answer-chosen'
                    }

                    if(false == review?.isGoodVisual){
                        answerNoChosen = 'review-answer-chosen'
                    }

                    setModal({content: <div style={{padding: '20px 10px'}}>
                        <div className='meal-header' style={{position: 'relative', backgroundColor: 'white'}}>
                            <div className='d-flex'>
                                <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                            </div>
                            
                            <div className='presence-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                            
                            <div className='d-flex'>
                                <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                            </div>
                        </div>
                    
                        <div className='d-flex' style={{marginTop: '10px'}}>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step review-step-active'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                        </div>
            
                        <div className='review-modal-title'>
                            Question <span id="modal-question-number">3</span> sur 5
                        </div>
            
                        <div className='review-question'>
                            La quantité de mon plat était suffisante
                        </div>
            
                        <div className='review-answers'>
                            <div role='presentation' className={`review-answer ${answerYesChosen}`} onClick={() => saveAndGo(date, 4, 1)}>Oui</div>
                            <div role='presentation' className={`review-answer ${answerNoChosen}`} onClick={() => saveAndGo(date, 4, 0)}>Non</div>
                        </div>
                    </div>})
                })
            })
        }

        if( 4 == pageNumber ){
            axiosPrivate.put('/guests/meal/review', {
                dateMeal: current.format('YYYY-MM-DD'),
                isGoodQuantity: (1 == answer),
            }, {withCredentials: true}).then(() => {
                axiosPrivate.get(`/guests/meal/review?date=${date}`, {withCredentials: true}).then((response) => {
                    let review = response?.data?.mealReview
                    let answerYesChosen = ''
                    let answerNoChosen = ''
                    if(true == review?.isGoodTaste){
                        answerYesChosen = 'review-answer-chosen'
                    }

                    if(false == review?.isGoodTaste){
                        answerNoChosen = 'review-answer-chosen'
                    }

                    setModal({content: <div style={{padding: '20px 10px'}}>
                        <div className='meal-header' style={{position: 'relative', backgroundColor: 'white'}}>
                            <div className='d-flex'>
                                <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                            </div>
                            
                            <div className='presence-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                            
                            <div className='d-flex'>
                                <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                            </div>
                        </div>
                    
                        <div className='d-flex' style={{marginTop: '10px'}}>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step review-step-active'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                        </div>
            
                        <div className='review-modal-title'>
                            Question <span id="modal-question-number">4</span> sur 5
                        </div>
            
                        <div className='review-question'>
                            J&apos;ai aimé le gout de mon plat
                        </div>
            
                        <div className='review-answers'>
                            <div role='presentation' className={`review-answer ${answerYesChosen}`} onClick={() => saveAndGo(date, 5, 1)}>Oui</div>
                            <div role='presentation' className={`review-answer ${answerNoChosen}`} onClick={() => saveAndGo(date, 5, 0)}>Non</div>
                        </div>
                    </div>})
                })
            })
        }

        if( 5 == pageNumber ){
            axiosPrivate.put('/guests/meal/review', {
                dateMeal: current.format('YYYY-MM-DD'),
                isGoodTaste: (1 == answer),
            }, {withCredentials: true}).then(() => {
                axiosPrivate.get(`/guests/meal/review?date=${date}`, {withCredentials: true}).then((response) => {
                    let review = response?.data?.mealReview
                    let comments = review?.comment
                    setModal({content: <div style={{padding: '20px 10px'}}>
                        <div className='meal-header' style={{position: 'relative', backgroundColor: 'white'}}>
                            <div className='d-flex'>
                                <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                            </div>
                            
                            <div className='presence-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                            
                            <div className='d-flex'>
                                <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                            </div>
                        </div>
                    
                        <div className='d-flex' style={{marginTop: '10px'}}>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step'>&nbsp;</span>
                            <span className='review-step review-step-active'>&nbsp;</span>
                        </div>
            
                        <div className='review-modal-title'>
                            Question <span id="modal-question-number">5</span> sur 5
                        </div>
            
                        <div className='review-question'>
                            Commentaires
                        </div>
            
                        <div className='review-answers'>
                            <input id='comment-answer' type="text" value={comments}></input>
                        </div>

                        <div role='presentation' className='add-invit mb-3' onClick={() => saveAndGo(date, 6, document.querySelector('#comment-answer').value)}>Envoyer</div>
                    </div>})
                })
            })
        }

        if( 6 == pageNumber ){
            axiosPrivate.put('/guests/meal/review', {
                dateMeal: current.format('YYYY-MM-DD'),
                comment: answer,
            }, {withCredentials: true}).then(() => {
                setModal({content: <div style={{padding: '20px 10px', backgroundColor:'#DDF1EB'}}>
                    <div className='meal-header' style={{position: 'relative', fontWeight: 600, backgroundColor: 'transparent', fontSize: '24px', justifyContent: 'center'}}>
                        C&apos;est noté merci !
                    </div>
                
                    <div className='review-modal-title'>
                        <div style={{justifyContent: 'center', display: 'flex'}}><i style={{color:'#008059', fontSize: '90px'}} className="fa-solid fa-circle-check"></i></div>
                    </div>

                    <div className='review-modal-title' style={{justifyContent: 'center', display: 'flex', color: 'black'}}>
                        <div className='header-points'>
                            <img style={{width: '40px'}} src='/images/points-icon.png' alt='points'></img>
                            <span style={{fontSize: '17px'}}>+ 20</span>
                        </div>
                    </div>

                    <div role='presentation' onClick={() => displayReviewFrom(dayjs(currentDate).add(1, 'day').format('YYYY-MM-DD'))} className='review-modal-title' style={{color: 'black', textAlign: 'center'}}>
                        Passer au jour suivant
                    </div>
                </div>})
            })
        }

        // document.querySelector('#modal-question-number').innerText = pageNumber
    }

    const displayReviewFrom = (date)=> {
        axiosPrivate.get(`/guests/meal/review?date=${date}`, {withCredentials: true}).then((response) => {
            let review = response?.data?.mealReview
            axiosPrivate.get(`/guests/canteen/menu?date=${date}&slotType=1`, {withCredentials: true}).then((response) => {
                let currentDay = new Date(date)

                let answers = response.data.meals.meals.map((meal) => {
                    let answerChosen = ''
                    if(review?.canteenDish.id == meal.id){
                        answerChosen = 'review-answer-chosen'
                    }

                    return <div key={meal.id} role='presentation' className={`review-answer ${answerChosen}`} onClick={() => saveAndGo(date, 2, meal.id)}>{meal.name}</div>
                })
    
                setModal({content: <div style={{padding: '20px 10px'}}>
                    <div className='meal-header' style={{position: 'relative', backgroundColor: 'white'}}>
                        <div className='d-flex'>
                            <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                        </div>
                        
                        <div className='presence-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                        
                        <div className='d-flex'>
                            <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                        </div>
                    </div>
                
                    <div className='d-flex' style={{marginTop: '10px'}}>
                        <span className='review-step review-step-active'>&nbsp;</span>
                        <span className='review-step'>&nbsp;</span>
                        <span className='review-step'>&nbsp;</span>
                        <span className='review-step'>&nbsp;</span>
                        <span className='review-step'>&nbsp;</span>
                    </div>
    
                    <div className='review-modal-title'>
                        Question <span id="modal-question-number">1</span> sur 5
                    </div>
    
                    <div className='review-question'>
                        Quel plat as-tu mangé a midi ?
                    </div>
    
                    <div className='review-answers'>{answers}</div>
                </div>})
                setShowModal(true)
            }).catch(function (error){
                if(404 == error.response.status){
                    let currentDay = new Date(date)
                    setModal({content: <div style={{padding: '20px 10px'}}>
                        <div className='meal-header' style={{position: 'relative', backgroundColor: 'white'}}>
                            <div className='d-flex'>
                                <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                            </div>
                            
                            <div className='presence-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                            
                            <div className='d-flex'>
                                <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                            </div>
                        </div>
                    
                        <div className='d-flex justify-content-center'><img style={{width: '150px'}} src='/images/unsold-chef.png' alt='Invendus'></img></div>
                        <p style={{fontSize: '30px'}} className='px-4 text-center'>Fermé</p>
                    </div>})
                    setShowModal(true)
                }        
            })
        })
    }

    const showTeams = () => {
        document.querySelector('#filter-teams').classList.add('filter-active')
        document.querySelector('#filter-individual').classList.remove('filter-active')
        document.querySelector('#filter-my-team').classList.remove('filter-active')

        document.querySelector('#ranking-team').classList.remove('d-none')
        document.querySelector('#ranking-individual').classList.add('d-none')
        document.querySelector('#ranking-my-team').classList.add('d-none')
    }

    const showIndividual = () => {
        document.querySelector('#filter-teams').classList.remove('filter-active')
        document.querySelector('#filter-individual').classList.add('filter-active')
        document.querySelector('#filter-my-team').classList.remove('filter-active')

        document.querySelector('#ranking-team').classList.add('d-none')
        document.querySelector('#ranking-individual').classList.remove('d-none')
        document.querySelector('#ranking-my-team').classList.add('d-none')
    }

    const showMyTeam = () => {
        document.querySelector('#filter-teams').classList.remove('filter-active')
        document.querySelector('#filter-individual').classList.remove('filter-active')
        document.querySelector('#filter-my-team').classList.add('filter-active')

        document.querySelector('#ranking-team').classList.add('d-none')
        document.querySelector('#ranking-individual').classList.add('d-none')
        document.querySelector('#ranking-my-team').classList.remove('d-none')
    }

    return ( reviewsDisplay &&
        <div className='home-page d-flex justify-content-center flex-column'>
            <div className='meal-header'>
                <div style={{fontWeight: 600, fontFamily: 'Poppins', fontSize: '22px'}}>Quel est le défi aujourd’hui ?</div>
                <div className='header-points'>
                    <img style={{width: '40px'}} src='/images/points-icon.png' alt='points'></img>
                    <span className='avatar-name'>{userPoints}</span>
                </div>
            </div>

            <div style={{'margin': '70px 15px 0px'}} className='d-flex flex-column'>
                <div className='home-head'>Défi 1 : donne ton avis sur tes repas !</div>
                <div className='home-vote-info'>
                    <div style={{'fontWeight': 'bold', textAlign: 'center'}}>Ma semaine de feedback</div>

                    <div className='challenge-week'>
                        {challengeWeek}
                    </div>
                    
                    <div className='d-flex align-items-center justify-content-center'>
                        <div style={{'fontWeight': 500, 'fontSize': '12px', margin: '10px 0'}}>Enchaine les feedbacks, complète ta semaine pour gagner le bonus de 5 points !</div>
                        <div className='d-flex'><img style={{width: '40px'}} src='/images/points-icon-white.png' alt='points'></img><span style={{'fontSize':'20px', 'fontWeight': 'bold'}}>+5</span></div>
                    </div>

                    <div role='presentation' onClick={() => displayReviewFrom(dayjs(currentDate).format('YYYY-MM-DD'))} className='home-vote-submit' style={{width: '215px'}}>Je donne mon avis</div>
                </div>
            </div>

            <div style={{'margin': '35px 15px 20px'}} className='d-flex flex-column'>
                <div className='home-head'>Défi 2: Anticipe et gagne plus de points</div>
                <Swiper className='full-height' slidesPerView="1.5">
                    <SwiperSlide key={0}>
                        <div>
                            <div className='home-vote-info' style={{minHeight: '265px'}}>
                                <div style={{'fontWeight': 'bold'}}>Vote pour tes menus favoris et gagne des points</div>
                                <div className='d-flex align-items-center justify-content-center flex-grow-1 d-flex'>
                                    <div style={{'fontWeight': 500, 'fontSize': '12px', margin: '10px 0', textAlign: 'left'}}>Gagne des points bonus si tu réalises plus de 5 votes d’affilées</div>
                                    <div className='d-flex'><img style={{width: '40px'}} src='/images/points-icon-white.png' alt='points'></img><span style={{'fontSize':'20px', 'fontWeight': 'bold'}}>+5</span></div>
                                </div>

                                <Link to={`/meal/${currentDate}`}>
                                    <div className='home-vote-submit'>C&apos;est parti</div>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={1}>
                        <div>
                            <div className='home-vote-info' style={{minHeight: '265px'}}>
                                <div style={{'fontWeight': 'bold'}}>Plus tu anticipes, plus tu gagnes !</div>
                                <div className='d-flex align-items-center justify-content-center flex-grow-1'>
                                    <div style={{'fontWeight': 500, 'fontSize': '12px', margin: '10px 0', textAlign: 'left'}}>Vote pour ton plat favori plusieurs semaine a l’avance pour gagner un max de points par jour de vote</div>
                                </div>

                                <div className='d-flex' style={{marginBottom: '10px'}}>
                                    <div className='d-flex m-1 flex-column align-items-center justify-content-center' style={{padding: '5px', width:'60px', backgroundColor:'#FDBD4D', borderRadius: '10px'}}>
                                        <span className='challenge-plus-points'>+10</span>
                                        <span style={{fontSize: '10px', color: 'black'}}>S-1</span>
                                    </div>
                                    <div className='d-flex m-1 flex-column align-items-center justify-content-center' style={{padding: '5px', width:'60px', backgroundColor:'#FDBD4D', borderRadius: '10px'}}>
                                        <span className='challenge-plus-points'>+20</span>
                                        <span style={{fontSize: '10px', color: 'black'}}>S-2</span>
                                    </div>
                                    <div className='d-flex m-1 flex-column align-items-center justify-content-center' style={{padding: '5px', width:'60px', backgroundColor:'white', borderRadius: '10px'}}>
                                        <span className='challenge-plus-points'>+30</span>
                                        <span style={{fontSize: '10px', color: 'black'}}>S-3</span>
                                    </div>
                                </div>

                                <Link to={`/meal/${currentDate}`}>
                                    <div className='home-vote-submit'>C&apos;est parti</div>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            <div style={{'margin': '5px 15px 75px'}} className='d-flex flex-column'>
                <div className='home-head align-items-center' style={{marginBottom: '5px'}}>
                    <div>Classement</div>
                    <div className='d-flex flex-grow-1 justify-content-end'>
                        {(teamDisplay && teamates.length > 0) &&<div role='presentation' onClick={showManageTeam} style={{backgroundColor: 'white', borderRadius: '20px'}}><i className="challenge-add-teamates fa-solid fa-user-plus"></i></div>}
                    </div>
                </div>

                <div className='meal-filters d-flex flex-row justify-content-center flex-grow-1 flex-shrink-1' style={{margin: 0, fontSize: '14px', letterSpacing: '0.5px', padding: '5px'}}>
                    <div id='filter-teams' onClick={showTeams} role='presentation' className='filter-active d-flex flex-column justify-content-center align-items-center'>
                        <div>Par équipe</div>
                    </div>

                    <div id='filter-individual' onClick={showIndividual} role='presentation' className='d-flex flex-column justify-content-center align-items-center'>
                        <div>Individuel</div>
                    </div>

                    <div id='filter-my-team' onClick={showMyTeam} role='presentation' className='d-flex flex-column justify-content-center align-items-center' style={{margin:0}}>
                        {(teamDisplay && teamates.length > 0) && 
                            <div>Mon équipe</div>
                        }
                    </div>
                </div>

                <div id='ranking-team'>
                    {topTeams.map((member, key) =>
                        <div key={key} className={`challenge-ranking ${ (member.myself) ? 'my-team' : '' }`}>
                            <div style={{margin: '0 10px'}}><img className='team-avatar' src={`/images/${member.avatar}`} alt='Equipe 1'></img></div>
                            <div style={{marginRight: '5px', fontWeight: 'bold'}}>#{member.rank}</div>
                            <div style={{fontWeight: 'bold'}}>{member.pseudo}</div>
                            <div className='d-flex flex-grow-1 justify-content-end'>
                                <div style={{marginRight: '10px'}}><img style={{width: '24px', marginRight: '3px'}} src='/images/points-icon.png' alt='points'></img> {member.points}</div>
                                <div><i style={{fontSize: '20px', color: 'orange'}} className="fa-solid fa-equals"></i></div>
                            </div>
                        </div>
                    )}
                </div>

                <div id='ranking-individual' className='d-none'>
                    {topUsers.map((member, key) =>
                        <div key={key} className={`challenge-ranking ${ (member.myself) ? 'my-team' : '' }`}>
                            <div style={{margin: '0 10px'}}><img className='team-avatar' onError={({ currentTarget }) => {currentTarget.onerror = null; currentTarget.src='/images/avatar-no-image.png';}} src={`/images/${member.avatar}`} alt={'' != member.pseudo ? member.pseudo : member.firstName.substring(0, 1) + '****** ' + member.lastName.substring(0, 1) + '.' }></img></div>
                            <div style={{marginRight: '5px', fontWeight: 'bold'}}>#{member.rank}</div>
                            <div style={{fontWeight: 'bold'}}>{ ('' !== member.pseudo && null !== member.pseudo ) ? member.pseudo : member.firstName.substring(0, 1) + '****** ' + member.lastName.substring(0, 1) + '.' }</div>
                            <div className='d-flex flex-grow-1 justify-content-end'>
                                <div style={{marginRight: '10px'}}><img style={{width: '24px', marginRight: '3px'}} src='/images/points-icon.png' alt='points'></img> {member.points}</div>
                                <div><i style={{fontSize: '20px', color: 'orange'}} className="fa-solid fa-equals"></i></div>
                            </div>
                        </div>
                    )}
                </div>

                <div id='ranking-my-team' className='d-none'>
                    {(teamDisplay && teamates.length > 0) && <div id='ranking-my-team'  style={{margin: '0 10px 80px 10px'}}>
                        { 1 == teamates.length && 
                            <div style={{color:'#FF5001'}}>Il faut être au minimum 2 dans une équipe</div>
                        }

                        {teamates.map((member, key) =>
                            <div key={key} className={`challenge-ranking ${ (member.myself) ? 'my-team' : '' }`}>
                                <div style={{margin: '0 10px'}}><img className='team-avatar' src={`/images/${member.avatar}`} alt='Equipe 1'></img></div>
                                <div style={{marginRight: '5px', fontWeight: 'bold'}}>#{member.rank}</div>
                                <div style={{fontWeight: 'bold'}}>{member.pseudo}</div>
                                <div className='d-flex flex-grow-1 justify-content-end'>
                                    <div style={{marginRight: '10px'}}><img style={{width: '24px', marginRight: '3px'}} src='/images/points-icon.png' alt='points'></img> {member.points}</div>
                                    <div><i style={{fontSize: '20px', color: 'orange'}} className="fa-solid fa-equals"></i></div>
                                </div>
                            </div>
                        )}
                    </div>
                    }
                </div>
            </div>

            <MenuFooter item='review' unsoldsRemain={0}></MenuFooter>
        </div>
    )
};

export default ChallengePage;
