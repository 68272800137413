import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import MenuFooter from '../../components/MenuFooter';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import MealCard from '../../components/Meal/MealCard';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useForm } from 'react-hook-form';

const MyHomePage = () => {
    const [unsolds, setUnsolds] = useState(true);
    const [cookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]);
    // const [cookies] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_user`]);
    const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id']
    const axiosPrivate = useAxiosPrivate();
    const today = new Date()
    const currentDate = today.toJSON().slice(0, 10)
    const completeDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    const completeMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    const navigate = useNavigate();
    const param = useParams()
    const { auth, setIsLoading, setFlash, setShowFlash,  } = useAuth();
    const [scenario, setScenario] = useState('no-answer');
    const [,setCalendarDisplay] = useState(false);
    const [mealsDisplay, setMealsDisplay] = useState(false);
    const [, setMeals] = useState(null);
    const [mealsCards, setMealsCards] = useState(null);
    const [, setSlots] = useState([]);
    const [userAllergens, setUserAllergens] = useState();
    const [userDietaryConstraints, setUserDietaryConstraints] = useState();
    const [menuId, setMenuId] = useState('0');
    const [, setReserved] = useState(false);
    const [total, setTotal] = useState(0);
    const [, setReservations] = useState([])
    const [, setTodayReservation] = useState(null)
    const [, setTodayComing] = useState(false)
    const [reservationInfos, setReservationInfos] = useState([])
    const [, setReservationPlatter] = useState([])
    const [, setReservationMeal] = useState([])
    const [, setReservationAccomp] = useState([])
    const [, setReservationCheese] = useState([])
    const [, setReservationDessert] = useState([])
    const [, setDisplayScenario] = useState(false);
    const [teamDisplay, setTeamDisplay] = useState(false);
    const [teamates, setTeamates] = useState([]);
    const [teamName, setTeamName] = useState('');
    const [teamAvatar, setTeamAvatar] = useState('');
    const [teamCode, setTeamCode] = useState('');
    const [teamOwner, setTeamOwner] = useState(false);
    const { reset, register, getValues, setValue } = useForm();
    const { setModal, setShowModal } = useAuth();
    // const team = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['team'] ?? ' '

    const getReservations = async () => {
        setIsLoading(true);
        try {     
            const result = await axiosPrivate.get(`/guests/reservation/meal?currentDate=${currentDate}&date=${currentDate}&canteenId=${canteenId}`, { withCredentials: true })
            const reservationsData = result.data['dates']
            setReservations(reservationsData)
            setTodayReservation(reservationsData[currentDate]['reserved'])
            setTodayComing(reservationsData[currentDate]['here'])

            if( null == reservationsData[currentDate]['reserved'] ){
                setScenario('no-answer')
            }

            if( true == reservationsData[currentDate]['reserved'] ){
                setScenario('he-comes')
            }

            if( false == reservationsData[currentDate]['reserved'] ){
                setScenario('he-dont-come')
            }

            setCalendarDisplay(true)
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            if('Canteen not exist' == error.response.data['hydra:description']){
                setScenario('no-canteen')
            }
        }
    };

    const getTeam = async () => {
        try {     
            const result = await axiosPrivate.get('/guests/team/members', { withCredentials: true })
            setTeamates(result.data.members)
            setTeamName(result.data.teamId)
            setTeamAvatar(result.data.teamAvatar)
            setTeamCode(result.data.teamCode)
            setTeamOwner(result.data.teamOwner)
        } catch (error) {
            console.log(error)
        }
        setTeamDisplay(true)
    }

    const getMeals = async () => {
        let gMenuId = 0
        try {     
            const result = await axiosPrivate.get(`/guests/canteen/menu?date=${currentDate}&canteenId=${canteenId}&slotType=1`, { withCredentials: true })
            setMeals(result.data)

            // if( null !== result.data ){
            //     setMealsCards(result.data.map((meal, key) =>
            //         <SwiperSlide key={key}><MealCard expired={true} userAllergens={userAllergens} userDietaryConstraints={userDietaryConstraints} getValues={getValues} setValue={setValue} id={meal.id} register={register} total={total} setTotal={setTotal} key={key} category={''} name={meal.name} co2={meal.co2} kcal={meal.kcal} proteen={meal.proteen} glucids={meal.glucids} lipids={meal.lipids} sugar={meal.sugar} price={meal.price} components={meal.ingredients.split(', ')} tags={meal.tags} allergens={meal.allergens} dietaryConstraints={meal.dietary_constraints} accompaniements={[]}></MealCard></SwiperSlide>
            //     ))
            // }

            setMenuId(result.data.infos.menu_id)

            let timeSlots = []
            result.data.stats.forEach((stat) => {
                timeSlots.push(stat.slot)
            })

            setSlots(timeSlots)

            const responseUser = await axiosPrivate.get('/guests/setting', {
                params: {
                    childId: auth.childId,
                },
                withCredentials: true,
            });
            setUserAllergens(responseUser.data.allergens)
            setUserDietaryConstraints(responseUser.data.dietaryConstraints)

            gMenuId = result.data.infos.menu_id
        } catch (error) {
            console.log(error)
            if(404 == error.response.status && 'Canteen not exist' == error.response.data['hydra:description']){
                setScenario('no-canteen')
            }else{
                if( 404 == error.response.status ){
                    setScenario('no-menu')
                }
            }        
        }
        setIsLoading(false);
        setDisplayScenario(true)

        return gMenuId;
    };

    useEffect(() => {
        if (auth?.canteenId === null && auth?.childId === null) {
            navigate('/register-model');
        }
    }, []);

    useEffect(() => {
        setDisplayScenario(false)
        setIsLoading(true)

        if( null != canteenId ){
            getUnsolds()
            getTeam()
            getReservations().then(() => getMeals().then((gMenuId) => getUserReservations(gMenuId)));
        }else{
            setScenario('no-canteen')
            setIsLoading(false);
            setDisplayScenario(true)
        }
    }, [param.date, param.type]);

    useEffect(() => {
        setReserved(false);
    }, [menuId, param.date, param.type, scenario]);

    const getUserReservations = async (gMenuId) => {
        setIsLoading(true);
        try {
            const result = await axiosPrivate.get(`/guests/reservation/menu/meal?menu_id=${gMenuId}&canteenId=${canteenId}`, { withCredentials: true })
            setReserved(true);
            setReservationInfos(result.data.mealsInformations)
            setReservationPlatter(result.data.mealsInformations.find((meal) => meal.type == 1)?.name)
            setReservationMeal(result.data.mealsInformations.find((meal) => meal.type == 2)?.name)
            setReservationAccomp(result.data.mealsInformations.find((meal) => meal.type == 4)?.name)
            setReservationCheese(result.data.mealsInformations.find((meal) => meal.type == 5)?.name)
            setReservationDessert(result.data.mealsInformations.find((meal) => meal.type == 3)?.name)
            setTotal(result.data?.total);
            reset({
                dishes: result.data?.meals.map((meal) => String(meal)) ?? [],
                attendance: result.data?.time.slice(0,5),
            });

            if( null !== result.data ){
                let mealsCardos = []
                result.data.mealsInformations.map((meal, key) => {
                    mealsCardos.push(<SwiperSlide key={key}><MealCard expired={false} userAllergens={userAllergens} userDietaryConstraints={userDietaryConstraints} getValues={getValues} setValue={setValue} id={meal.id} register={register} total={total} setTotal={setTotal} key={key} category={''} name={meal.name} co2={meal.co2} kcal={meal.kcal} proteen={meal.proteen} glucids={meal.glucids} lipids={meal.lipids} sugar={meal.sugar} price={meal.price} components={meal.ingredients.split(', ')} tags={meal.tags} allergens={meal.allergens} dietaryConstraints={meal.dietary_constraints} accompaniements={[]}></MealCard></SwiperSlide>)
                })

                setMealsCards(mealsCardos)
            }
        } catch (error) {
            setReservationInfos([])
            reset({
                dishes: [],
                attendance: null,
            });
        }
        setIsLoading(false);
        setMealsDisplay(true);
    };

    const getUnsolds = async () => {
        try {    
            const result = await axiosPrivate.get(`/guests/canteen/unsolds?canteenId=${canteenId}&requestedDate=${currentDate}`, { withCredentials: true })
            setUnsolds(result.data)
        } catch (error) {
            setUnsolds({remains:null})
        }
    }

    useEffect(() => {
        getUnsolds()
    }, [])

    const saveAvatar = (avatar) => {
        document.querySelectorAll('avatar-choice-card').forEach((card) => {
            if(card.querySelector('img').src == `/images/${avatar}`){
                card.classList.add('avatar-selected')
            }else{
                card.classList.remove('avatar-selected')
            }
        })

        axiosPrivate.put('/guests/avatar', {'avatar': avatar}, { withCredentials: true })
    }

    const saveNickname = (nickname) => {
        axiosPrivate.put('/guests/nickname', {'nickname': nickname}, { withCredentials: true })
    }

    const saveTeamAvatar = (avatar) => {
        document.querySelectorAll('avatar-choice-card').forEach((card) => {
            if(card.querySelector('img').src == `/images/${avatar}`){
                card.classList.add('avatar-selected')
            }else{
                card.classList.remove('avatar-selected')
            }
        })

        axiosPrivate.put('/guests/team/avatar', {'avatar': avatar}, { withCredentials: true })
    }

    const chooseAvatar = (action) => {
        if( 'create' == action ){
            axiosPrivate.post('/guests/team', { withCredentials: true })
        }

        setModal({
            title: <div className='d-flex justify-content-center align-items-center py-2 mb-3'>Création d&apos;équipe</div>,
            content: <>
                <div className='d-flex' style={{margin: '10px'}}>
                    <span className='review-step review-step-active'>&nbsp;</span>
                    <span className='review-step'>&nbsp;</span>
                    <span className='review-step'>&nbsp;</span>
                </div>
                <div style={{marginBottom: '10px', textAlign: 'center', color: '#FF5001'}}>Choisis ton avatar</div>
                <div className='d-flex justify-content-center align-items-center px-4' style={{flexWrap: 'wrap'}}>
                    <div onClick={() => saveAvatar('avatar-girl-1.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-1.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-girl-2.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-2.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-girl-3.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-3.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-girl-4.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-4.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-girl-5.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-5.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-girl-6.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-girl-6.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-boy-1.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-1.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-boy-2.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-2.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-boy-3.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-3.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-boy-4.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-4.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-boy-5.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-5.png' alt='Avatar'></img></div>
                    <div onClick={() => saveAvatar('avatar-boy-6.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-boy-6.png' alt='Avatar'></img></div>
                </div>

                <div role='presentation' onClick={() => choosePseudo(action)} type="submit" className='meal-submit border-0'>Valider</div>
            </>
        })
        setShowModal(true)
    }

    const choosePseudo = (action) => {
        setModal({
            title: <div className='d-flex justify-content-center align-items-center py-2 mb-3'>Création d&apos;équipe</div>,
            content: <>
                <div className='d-flex' style={{margin: '10px'}}>
                    <span className='review-step'>&nbsp;</span>
                    <span className='review-step review-step-active'>&nbsp;</span>
                    <span className='review-step'>&nbsp;</span>
                </div>
                <div style={{marginBottom: '10px', textAlign: 'center', color: '#FF5001'}}>Choisis un pseudo</div>
                <div className='d-flex justify-content-center align-items-center px-4' style={{flexWrap: 'wrap'}}>
                    Pseudo
                    <input id='pseudo' type='text'></input>
                </div>

                { ('create' === action) && <div role='presentation' onClick={chooseTeamAvatar} type="submit" className='meal-submit border-0'>Valider</div> }
                { ('join' === action) && <div role='presentation' onClick={joinTeam} type="submit" className='meal-submit border-0'>Valider</div> }
            </>
        })
        setShowModal(true)
    }

    const joinTeam = () => {
        saveNickname(document.querySelector('#pseudo').value)
        
        setModal({
            title: <div className='d-flex justify-content-center align-items-center py-2 mb-3'>Rejoindre une équipe</div>,
            content: <>
                <div className='d-flex' style={{margin: '10px'}}>
                    <span className='review-step'>&nbsp;</span>
                    <span className='review-step'>&nbsp;</span>
                    <span className='review-step review-step-active'>&nbsp;</span>
                </div>

                <div className='d-flex justify-content-center align-items-center px-4' style={{flexWrap: 'wrap'}}>
                    Code équipe
                    <input id='join-team-code' type='text'></input>
                </div>

                <div role='presentation' onClick={joinTeamCode} type="submit" className='meal-submit border-0'>Valider</div>
            </>
        })
        setShowModal(true)
    }

    const joinTeamCode = () => {
        axiosPrivate.put('/guests/team/join', {'code': document.querySelector('#join-team-code').value}, { withCredentials: true }).then(() => {
            location.reload()
        })
    }

    const finalizeTeamCreation = () => {
        setShowModal(false)
        location.reload()
    }

    const copyTeamCode = (teamCode) => {
        navigator.clipboard.writeText(teamCode);
        setFlash({ message: 'Code Copié', type: 'success' });
        setShowFlash(true);
    }

    const deleteTeam = () => {
        axiosPrivate.delete('/guests/team', {data:{}, withCredentials: true }).then(() => {
            setShowModal(false)
            location.reload()
        })
    }

    const leaveTeam = () => {
        axiosPrivate.delete('/guests/team/member', {data:{}, withCredentials: true }).then(() => {
            setShowModal(false)
            location.reload()
        })
    }

    const showManageTeam = () => {
        setModal({
            title: <div className='d-flex justify-content-center align-items-center py-2 mb-3'>Gestion de l&apos;équipe</div>,
            content: <div style={{margin: '0 10px 10px 10px'}}>
                <div className='review-answer' role='presentation' onClick={() => copyTeamCode(teamCode)}>Copier le code équipe - {teamCode}</div>
                {teamOwner && <div className='review-answer' role='presentation' onClick={deleteTeam}>Supprimer mon équipe</div>}
                {!teamOwner && <div  className='review-answer' role='presentation' onClick={leaveTeam}>Quitter mon équipe</div>}
            </div>
        })
        setShowModal(true)
    }

    const chooseTeamAvatar = () => {
        saveNickname(document.querySelector('#pseudo').value)

        setModal({
            title: <div className='d-flex justify-content-center align-items-center py-2 mb-3'>Création d&apos;équipe</div>,
            content: <>
                <div className='d-flex' style={{margin: '10px'}}>
                    <span className='review-step'>&nbsp;</span>
                    <span className='review-step'>&nbsp;</span>
                    <span className='review-step review-step-active'>&nbsp;</span>
                </div>
                <div style={{marginBottom: '10px', textAlign: 'center', color: '#FF5001'}}>Choisis ton avatar</div>
                <div className='d-flex justify-content-center align-items-center px-4' style={{flexWrap: 'wrap'}}>
                    <div onClick={() => saveTeamAvatar('avatar-team-1.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-team-1.png' alt='Avatar'></img></div>
                    <div onClick={() => saveTeamAvatar('avatar-team-2.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-team-2.png' alt='Avatar'></img></div>
                    <div onClick={() => saveTeamAvatar('avatar-team-3.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-team-3.png' alt='Avatar'></img></div>
                    <div onClick={() => saveTeamAvatar('avatar-team-4.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-team-4.png' alt='Avatar'></img></div>
                    <div onClick={() => saveTeamAvatar('avatar-team-5.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-team-5.png' alt='Avatar'></img></div>
                    <div onClick={() => saveTeamAvatar('avatar-team-6.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-team-6.png' alt='Avatar'></img></div>
                    <div onClick={() => saveTeamAvatar('avatar-team-7.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-team-7.png' alt='Avatar'></img></div>
                    <div onClick={() => saveTeamAvatar('avatar-team-8.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-team-8.png' alt='Avatar'></img></div>
                    <div onClick={() => saveTeamAvatar('avatar-team-9.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-team-9.png' alt='Avatar'></img></div>
                    <div onClick={() => saveTeamAvatar('avatar-team-10.png')} role='presentation' className='avatar-choice-card'><img src='/images/avatar-team-10.png' alt='Avatar'></img></div>
                </div>

                <div onClick={finalizeTeamCreation} role='presentation' type="submit" className='meal-submit border-0'>Commencer</div>
            </>
        })
        setShowModal(true)
    }

    return (
        <div className='home-page d-flex min-vh-100 justify-content-center flex-column'>
            <div className='meal-header'>
                <ProfileHeader bg='home-header' page='home'></ProfileHeader>
            </div>

            <div style={{'flex': 0, 'marginBottom': '20px', marginTop: '60px'}} className='presence-complete-day'>{ completeDays[today.getDay()] } {String(today.getDate()).padStart(2, '0')} { completeMonths[today.getMonth()] }</div>

            <div style={{'margin': '5px 15px 20px'}} className='d-flex flex-column'>
                <div className='home-head'>Votes</div>
                <div className='home-vote-info'>
                    <div style={{'fontWeight': 'bold'}}>Vote pour tes menus favoris et gagne des points</div>
                    
                    <div className='d-flex align-items-center justify-content-center'>
                        <div style={{'fontWeight': 500, 'fontSize': '12px', margin: '10px 0'}}>Gagne des points bonus si tu réalises plus de 5 votes d’affilées</div>
                        <div className='d-flex'><img style={{width: '40px'}} src='/images/points-icon-white.png' alt='points'></img><span style={{'fontSize':'20px', 'fontWeight': 'bold'}}>+5</span></div>
                    </div>

                    <Link to={`/meal/${currentDate}`}>
                        <div className='home-vote-submit'>Voter</div>
                    </Link>
                </div>
            </div>

            <div style={{'margin': '5px 15px 20px'}} className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                    <div className='home-head'>Mon vote du jour</div>
                    <div className='home-menu-link'><Link to={`/meal/${currentDate}`}>Voir le menu</Link></div> 
                </div>

                { mealsDisplay && 0 == reservationInfos.length && 
                    <div style={{'margin': '5px 0px'}} className='home-voting'>
                        <div>
                            <i style={{color: 'black', margin: '0 15px', fontSize: '40px'}} className="fa-solid fa-bell"></i>
                        </div>
                        <div style={{'fontSize': '12px', padding: '10px 0'}} className='d-flex flex-column'>
                            <span className='home-vote-closed'>Votes clos</span>
                            <span>Aucun vote enregistré pour aujourd&apos;hui</span>
                        </div>
                    </div>}

                { mealsDisplay && reservationInfos.length > 0 && 
                    <div className='meal-cards-list'>
                        <Swiper slidesPerView="2.5">{mealsCards}</Swiper>
                    </div> 
                }
            </div>

            <div style={{'margin': '5px 15px 5px'}} className='home-info-closing'>
                <div>
                    <i style={{color: 'black', margin: '0 15px', fontSize: '24px'}} className="fa-solid fa-circle-info"></i>
                </div>
                <div style={{'fontSize': '12px', padding: '10px 0'}}>
                    <span>Les votes ferment bientôt. </span>
                    <span>Pour aider le chef à réduire le gaspillage, anticipez dès maintenant vos prochains votes !</span>
                </div>
            </div>

            <div style={{'margin': '5px 15px 20px'}} className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                    <div className='home-head align-items-center'>Mon équipe 
                        {(teamDisplay && teamates.length > 0) && <div className='d-flex align-items-center'>
                            <span>&nbsp; - Equipe {teamName}</span>
                            <span style={{marginLeft: '10px'}}><img style={{width: '40px'}} alt='Logo' src={`/images/${teamAvatar}`}></img></span>
                        </div>
                        }
                    </div>

                    {(teamDisplay && teamates.length > 0) &&
                        <div className='d-flex flex-grow-1 justify-content-end'>
                            <div role='presentation' onClick={showManageTeam} style={{backgroundColor: 'white', borderRadius: '20px'}}><i className="challenge-add-teamates fa-solid fa-user-plus"></i></div>
                        </div>
                    }
                </div>
            </div>

            {(teamDisplay && 0 == teamates.length) && <div className='d-flex justify-content-center' style={{marginBottom: '80px'}}>
                <div role='presentation' onClick={() => chooseAvatar('create')} className='home-team-action'>
                    <div><img style={{width:'90px'}} src='/images/create-team.png' alt='Créer une équipe'></img></div>
                    <div className='action-text'>Créer une équipe</div>
                </div>

                <div className='home-team-action'>
                    <div role='presentation' onClick={() => chooseAvatar('join')}><img style={{width:'90px'}} src='/images/join-team.png' alt='Rejoindre une équipe'></img></div>
                    <div className='action-text'>Rejoindre une équipe</div>
                </div>
            </div>
            }

            {(teamDisplay && teamates.length > 0) && <div id='ranking-my-team'  style={{margin: '0 10px 80px 10px'}}>
                { 1 == teamates.length && 
                    <div style={{color:'#FF5001'}}>Il faut être au minimum 2 dans une équipe</div>
                }

                {teamates.map((member, key) =>
                    <div key={key} className={`challenge-ranking ${ (member.myself) ? 'my-team' : '' }`}>
                        <div style={{margin: '0 10px'}}><img className='team-avatar' src={`/images/${member.avatar}`} alt='Equipe 1'></img></div>
                        <div style={{marginRight: '5px', fontWeight: 'bold'}}>#{member.rank}</div>
                        <div style={{fontWeight: 'bold'}}>{member.pseudo}</div>
                        <div className='d-flex flex-grow-1 justify-content-end'>
                            <div style={{marginRight: '10px'}}><img style={{width: '24px', marginRight: '3px'}} src='/images/points-icon.png' alt='points'></img> {member.points}</div>
                            <div><i style={{fontSize: '20px', color: 'orange'}} className="fa-solid fa-equals"></i></div>
                        </div>
                    </div>
                )}
            </div>
            }

            <MenuFooter item='home' unsoldsRemain={unsolds.remains}></MenuFooter>
        </div>
    )
};

export default MyHomePage;
