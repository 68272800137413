import React from 'react';
import useAuth from '../../hooks/useAuth';
import UnsoldDetails from './UnsoldDetails';

const UnsoldCard = ({ id, name, price, remain, tags }) => {
    const { setModal, setShowModal } = useAuth();
    
    const displayInformations = () => {
        setModal({content: <UnsoldDetails name={name} price={price} components={['Accompagnement surprise']} tags={tags} allergens={[]}></UnsoldDetails>})
        setShowModal(true)
    }

    const toggleQtyBtn = (id, sign) => {
        const minus = document.querySelector(`#fa-minus-${id}`)
        const plus = document.querySelector(`#fa-plus-${id}`)
        const qtyInput = document.querySelector(`#unsold-qty-${id}`);
        let qty = parseInt(qtyInput.value)
        if( '+' == sign && qty != qtyInput.max ){
            qty += 1;
        }

        if( '-' == sign && qty != qtyInput.min ){
            qty -= 1
        }

        minus.classList.remove('d-none')
        plus.classList.remove('d-none')

        if( 0 == qty ){
            minus.classList.add('d-none')
            plus.classList.remove('d-none')
        }

        if( qtyInput.max == qty ){
            plus.classList.add('d-none')
            minus.classList.remove('d-none')
        }

        qtyInput.value = qty
    }

    const tagsBlock = tags.map((tag, key) => {
        return <span className='unsold-tag' key={key}><img src={`/images/${tag.icon}`} alt={tag.name}></img></span>
    })

    return (
        <>
            <div className='unsold-card'>
                <div className='d-flex'>
                    <span className={ (remain > 0) ? 'd-flex flex-grow-1' : 'd-flex flex-grow-1 unsold-unavailable' }>{name}</span>
                    <span className='unsold-infos' role='presentation' onClick={displayInformations}><i className='fa-solid fa-circle-info'></i></span>
                    <span className='unsold-left'>restants</span>
                    <span className='unsolds-remain'>{remain}</span>
                </div>

                <div className='d-flex mt-2'>
                    <span className='unsolds-card-price'>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price)}</span>
                    {tagsBlock}
                    { (remain > 0) &&
                        <div>
                            <i id={`fa-minus-${id}`} role='presentation' onClick={() => toggleQtyBtn(id, '-')} className='d-none fa-solid fa-minus'></i>
                            <input id={`unsold-qty-${id}`} className='unsold-qty' type='number' defaultValue={0} min={0} max={remain}></input>
                            <i id={`fa-plus-${id}`} role='presentation' onClick={() => toggleQtyBtn(id, '+')} className='fa-solid fa-plus'></i>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default UnsoldCard
