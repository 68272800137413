import { useCookies } from 'react-cookie';
import axios from '../api/axios';
import useAuth from './useAuth';

const useLogout = () => {
    // Global
    const { auth, setAuth, setIsLoading } = useAuth();
    const [, removeCookie] = useCookies();

    // Logout
    return async () => {
        await axios.post('/users/token/invalidate', {
            refresh_token: auth?.refreshToken,
        }, { withCredentials: true });

        setAuth({});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, undefined, {path: '/'});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, undefined, {path: '/google/auth'});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, undefined, {path: '/'});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_children`, undefined, {path: '/'});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, undefined, {path: '/'});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, undefined, {path: '/'});
        localStorage.removeItem('pushSubscription');
        setIsLoading(false);
    }
};

export default useLogout;
