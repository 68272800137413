import React, { useEffect, useState } from 'react';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import MenuFooter from '../../components/MenuFooter';
import { useCookies } from 'react-cookie';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const MyQRCode = () => {
    const { setIsLoading } = useAuth();
    const [cookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]);
    const canteenName = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['name']
    const [reserved, setReserved] = useState(null)
    const [display, setDisplay] = useState(false)
    const axiosPrivate = useAxiosPrivate();
    const currentDate = new Date().toJSON().slice(0, 10)

    const getReservations = async () => {
        setIsLoading(true);
        try {     
            const result = await axiosPrivate.get(`/guests/reservation/meal?date=${currentDate}`, { withCredentials: true })
            const reservationsData = result.data['dates']

            setReserved(false)
            if( true == reservationsData[currentDate]['reserved'] ){
                setReserved(true)
            }

            setDisplay(true)
            setIsLoading(false);
        } catch (error) {
            setReserved(false)
            setDisplay(true)
            setIsLoading(false);
            console.log(error)
        }
    };

    useEffect(() => {
        setDisplay(false)
        setIsLoading(true)

        getReservations()
    }, []);
    
    if(reserved){
        return ( display &&
            <div className='home min-vh-100'>
                <div className='meal-header'>
                    <ProfileHeader bg='calendar_reservation' canteenName={canteenName} page='qr-code'></ProfileHeader>
                </div>
    
                <div id='news-infos'>
                    <div className='qrcode-resa' id='qrcode-scan'>
                        <img alt='good' src='images/good-review-icon-old.png'></img>
                    </div>
                    <div className='qr-code-label' style={{textAlign:'center'}}>
                        Ismaël TALBI<br></br>
                        26 janvier 2023<br></br>
                        Merci pour votre réservation
                    </div>
                </div>
    
                <MenuFooter></MenuFooter>
            </div>
        );
    }else{
        return ( display &&
            <div className='home min-vh-100'>
                <div className='meal-header'>
                    <ProfileHeader bg='calendar_reservation' canteenName={canteenName} page='qr-code'></ProfileHeader>
                </div>
    
                <div id='news-infos'>
                    <div className='qrcode-resa' id='qrcode-scan'>
                        <img alt='bad' src='images/bad-review-icon-old.png'></img>  
                    </div>
                    <div className='qr-code-label' style={{textAlign:'center'}}>Pensez à réserver la prochaine fois</div>
                </div>
    
                <MenuFooter></MenuFooter>
            </div>
        );
    }
};

export default MyQRCode;
