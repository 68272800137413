import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import InputField from '../../components/InputField';

const UserInvitation = () => {
    // Global
    const axiosPrivate = useAxiosPrivate();
    const [displayUserInfos, setDisplayUserInfos] = useState(false);
    const [invitations, setInvitations] = useState([])
    const { setModal, setShowModal, setIsLoading, setFlash, setShowFlash } = useAuth();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [cookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]);
    const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id'] ?? null
    const today = new Date().toJSON().slice(0, 10);

    // Form
    const { refDate, ...inputPropsDate } = register('date', {
        required: 'Date obligatoire',
        value: today,
    });
    const { refCount, ...inputPropsCount } = register('count', {
        required: 'Nombre d\'invités obligatoire',
    });

    const getInvitations = async () => {
        setIsLoading(true);
        const result = await axiosPrivate.get(`/guests/invitation?canteenId=${canteenId}`, { withCredentials: true })
        setInvitations(result.data.invitations)
        setIsLoading(false);
        setDisplayUserInfos(true)
    };

    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axiosPrivate.post('/guests/invitation', {
                date: data.date,
                count: data.count,
                canteenId: canteenId,
            }, { withCredentials: true })
            setShowModal(false)
            setFlash({ message: 'Invités ajoutés', type: 'success' });
            window.location.reload();
        } catch (err) {
            let error;
            if (err?.response?.status === 409) {
                error = 'Email déjà utilisé';
            } else {
                error = 'Une Erreur est survenue';
            }
            setFlash({ message: error });
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    const showForm = () => {
        setModal({content: <div style={{padding: '10px 20px'}}>
            <form className="d-flex flex-column" onSubmit={ handleSubmit(submit) } >
                <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1'>
                    <InputField id='date-invit' type='date' color="#000" label="Date *" className="user-input" error={!!errors.date} helper={errors?.date?.message} inputRef={refDate} inputProps={inputPropsDate}></InputField>
                    <InputField color="#000" label="Nombre d'invités *" className="user-input" error={!!errors.count} helper={errors?.count?.message} inputRef={refCount} inputProps={inputPropsCount}></InputField>
                </div>

                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <input style={{color:'white', backgroundColor:'#018C4E'}} className='user-submit' type='submit' value='Valider'></input>
                </div>
            </form>
        </div>})
        setShowModal(true)
    }

    // const editForm = () => {
    //     setModal({content: <div style={{padding: '10px 20px'}}>
    //         <form className="d-flex flex-column" onSubmit={ handleSubmit(submit) } >
    //             <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1'>
    //                 <InputField id='date-invit' type='date' color="#000" label="Date *" className="user-input" error={!!errors.date} helper={errors?.date?.message} inputRef={refDate} inputProps={inputPropsDate}></InputField>
    //                 <InputField color="#000" label="Nombre d'invités *" className="user-input" error={!!errors.count} helper={errors?.count?.message} inputRef={refCount} inputProps={inputPropsCount}></InputField>
    //             </div>

    //             <div className='d-flex flex-column align-items-center justify-content-center'>
    //                 <input style={{color:'white', backgroundColor:'#018C4E'}} className='user-submit' type='submit' value='Valider'></input>
    //             </div>
    //         </form>
    //     </div>})
    //     setShowModal(true)
    // }

    useEffect(() => {
        getInvitations();
    }, []);

    return (
        <>
            { displayUserInfos &&
                <div className='profil-page d-flex min-vh-100 justify-content-center'>
                    <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                        <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                            <div className='d-flex profile-header-back'>
                                <Link to='/my-profile'>
                                    <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                        <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                    </span>
                                </Link>
                            </div>
                            <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Invités</div>
                        </div>

                        <div className='d-flex flex-column profile-block'>
                            <div className='user-title d-flex justify-content-end'>
                                <div role='presentation' onClick={showForm} className='add-invit'>Ajouter des invités</div>
                            </div>
                        </div>

                        <div className='d-flex flex-column flex-grow-1'>
                            <div className='user-title d-flex justify-content-center'>
                                Liste des invitations
                            </div>
                                
                            { invitations.length > 0 &&
                                <TableContainer sx={{ boxShadow: {xs: 'none'}, backgroundColor: 'white', padding: {md: '20px 40px'} }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: '#635C5C', padding: {xs: '5px 10px;', md: '16px'}, fontSize: {xs: '13px', md: '15px'},  minWidth: {xl: 160} }}>Date</TableCell>
                                                <TableCell sx={{ color: '#635C5C', padding: {xs: '5px 10px;', md: '16px'}, fontSize: {xs: '13px', md: '15px'},  minWidth: {xs: 80, xl: 160} }}>Nombre</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { invitations.map((invitation) => {
                                                return <TableRow
                                                    key={invitation.date}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ padding: {xs: '0px 5px', md: '16px'}, fontSize: {xs: '13px', md: '15px'} }}>{new Date(invitation.date).toLocaleDateString('fr-FR')}</TableCell>
                                                    <TableCell sx={{ textAlign:'center', padding: {xs: '0px 5px', md: '16px'}, fontSize: {xs: '13px', md: '13px'} }}>{invitation.count}</TableCell>
                                                    {/* <TableCell sx={{ textAlign:'center', padding: {xs: '0px 5px', md: '16px'}, fontSize: {xs: '13px', md: '13px'} }}><i role='presentation' onClick={editForm} className='fa-solid fa-pen-to-square'></i></TableCell> */}
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }

                            { invitations.length == 0 &&
                                <div><i>Aucune invitation enregistrée</i></div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default UserInvitation;
