import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import InputField from '../../components/InputField';

const UserChangePassword = () => {
    // Global
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { setFlash, setShowFlash, setIsLoading } = useAuth();

    // Form
    const { refPassword, ...inputPropsPassword } = register('password', {
        required: 'Mot de passe obligatoire',
        minLength: {
            value: 8,
            message: 'Minimum 8 caractères',
        },
    });
    const { refRepeatPassword, ...inputPropsRepeatPassword } = register('repeatPassword', {
        validate: (value) => value === watch('password') || 'Mot de passe non identique',
    });

    // Call Submit Register Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axiosPrivate.put('/guests/password', {
                password: data.password,
            }, { withCredentials: true })
            setFlash({ message: 'Le mot de passe a été changé', type: 'success' });
        } catch (error) {
            setFlash({ message: 'Une Erreur est survenue' });
        }

        navigate('/my-profile');

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    return (
        <div className='profil-page d-flex min-vh-100 justify-content-center'>
            <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                    <div className='d-flex profile-header-back'>
                        <Link to='/my-profile'>
                            <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                            </span>
                        </Link>
                    </div>
                    <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Mon profil</div>
                </div>

                <div className='d-flex flex-column profile-block justify-content-center flex-grow-1'>
                    <div className='user-title d-flex justify-content-center'>
                        Changer mon mot de passe
                    </div>

                    <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1'>
                        <form className="d-flex flex-column" onSubmit={ handleSubmit(submit) } >
                            <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1'>
                                <InputField color="#000" className="user-input" label="Nouveau mot de passe *" type="password" error={!!errors.password} helper={errors?.password?.message ? errors.password.message : 'Doit contenir 8 caractères minimum'} inputRef={refPassword} inputProps={inputPropsPassword}></InputField>
                                <InputField color="#000" className="user-input" label="Confirmer le mot de passe *" type="password" error={!!errors.repeatPassword} helper={errors?.repeatPassword?.message} inputRef={refRepeatPassword} inputProps={inputPropsRepeatPassword}></InputField>
                            </div>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <input className='user-submit' type='submit' value='Valider'></input>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserChangePassword;
