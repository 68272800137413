import { useEffect, useState, useRef } from 'react';
import { Html5Qrcode } from 'html5-qrcode';

const useQRCodeScan = () => {
    // Global
    const [decodedQRData, setDecodedQrData] = useState({
        isScanning: false,
        isScanSuccess: false,
        isScanFailure: false,
        data: null,
        error: '',
    });
    const html5QrCodeScannerRef = useRef(null);

    useEffect(() => {
        return () => {
            stopQrCode();
        };
    }, []);

    function startQrCode(qrcodeMountNodeID = 'qrcode-scan', closeAfterScan = true, getQrBoxDimension = 300) {
        try {
            const html5qrCodeScanner = new Html5Qrcode(qrcodeMountNodeID);
            html5QrCodeScannerRef.current = html5qrCodeScanner;

            setDecodedQrData({
                ...decodedQRData,
                isScanning: true,
                data: null,
            });

            html5qrCodeScanner.start({facingMode: 'environment'}, { fps: 100, qrbox: getQrBoxDimension, aspectRatio: 1 },
                (qrCodeMessage) => {
                    setDecodedQrData({
                        ...decodedQRData,
                        isScanSuccess: true,
                        isScanning: false,
                        data: qrCodeMessage,
                        error: '',
                    });

                    if (closeAfterScan) {
                        html5QrCodeScannerRef.current = null;
                        html5qrCodeScanner.stop()
                            .then(() => {
                                console.log('stopped after successful scan');
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }, () => {}
            ).catch((err) => {
                setDecodedQrData({
                    ...decodedQRData,
                    isScanSuccess: false,
                    isScanning: false,
                    isScanFailure: true,
                    data: null,
                    error: err || 'QR Code parsing failed',
                });
            });
        } catch (e) {
            setDecodedQrData({
                ...decodedQRData,
                isScanSuccess: false,
                isScanning: false,
                isScanFailure: true,
                data: null,
                error: e || 'QR Code parsing failed',
            });
        }
    }

    function stopQrCode() {
        if (html5QrCodeScannerRef.current) {
            try {
                html5QrCodeScannerRef.current.stop()
                    .then(() => {
                        console.log('stopped after successful scan');
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (e) {
                setDecodedQrData({
                    ...decodedQRData,
                    isScanSuccess: false,
                    isScanning: false,
                    isScanFailure: true,
                    data: null,
                    error: e || 'QR Code parsing failed',
                });
            }

            html5QrCodeScannerRef.current = null;
        }
    }

    return [startQrCode, decodedQRData, stopQrCode];
};

export default useQRCodeScan;
