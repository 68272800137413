import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import useAuth from '../hooks/useAuth';


const Loader = () => {
    // Global
    const { isLoading } = useAuth();

    return (
        <>
            <Backdrop sx={{ color: '#FFF', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default Loader;
