import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';


const CO2Details = () => {
    const { auth, setIsLoading } = useAuth();
    const [, setScenario] = useState('no-answer');
    const completeDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    const completeMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    const [cookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]);
    const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id']
    const axiosPrivate = useAxiosPrivate();
    const [reservationInfos, setReservationInfos] = useState([])
    const [mealsDisplay, setMealsDisplay] = useState(false)
    const [, setMenuId] = useState('0');
    const [co2Total, setCO2Total] = useState(null);
    const [carTotal, setCarTotal] = useState(null);
    const [trainTotal, setTrainTotal] = useState(null);

    let params = useParams()
    let today = new Date()
    let currentDate = params?.date ? params.date : today.toJSON().slice(0, 10)
    let currentDay = new Date(currentDate)

    const getCanteenInfos = async () => {
        const result = await axiosPrivate.get(`/guests/canteen?canteenId=${canteenId}`, {
            withCredentials: true,
            params: {
                childId: auth.childId,
            },
        })
        return result.data.canteen
    }

    const getMeals = async () => {
        let gMenuId = 0
        try {     
            const result = await axiosPrivate.get(`/guests/canteen/menu?date=${currentDate}&canteenId=${canteenId}&slotType=1`, { withCredentials: true })
            setMenuId(result.data.infos.menu_id)
            gMenuId = result.data.infos.menu_id
        } catch (error) {
            console.log(error)
            if(404 == error.response.status && 'Canteen not exist' == error.response.data['hydra:description']){
                setScenario('no-canteen')
            }else{
                if( 404 == error.response.status ){
                    setScenario('no-menu')
                }
            }        
        }
        setIsLoading(false);

        return gMenuId;
    };
    const current = dayjs(currentDate);

    const dateBefore = () => {
        window.location = `/co2-details/${current.add(-1, 'day').format('YYYY-MM-DD')}`;
    };

    const dateAfter= () => {
        window.location = `/co2-details/${current.add(1, 'day').format('YYYY-MM-DD')}`;
    };

    const getUserReservations = async (gMenuId) => {
        setIsLoading(true);
        try {
            const result = await axiosPrivate.get(`/guests/reservation/menu/meal?menu_id=${gMenuId}&canteenId=${canteenId}`, { withCredentials: true })
            setReservationInfos(result.data.mealsInformations)

            let co2 = 0;
            let car = 0;
            let train = 0;
            result.data.mealsInformations.forEach((resa) => {
                co2 += parseFloat(resa.co2)
                car += parseFloat(resa.car)
                train += parseFloat(resa.train)
            })
            setCO2Total(co2)
            setCarTotal(car)
            setTrainTotal(train)
        } catch (error) {
            setReservationInfos([])
        }
        setIsLoading(false);
        setMealsDisplay(true);
    };

    useEffect(() => {
        getCanteenInfos()
        getMeals().then((gMenuId) => getUserReservations(gMenuId))
    }, [])

    return (
        <div className='home-page d-flex unsolds min-vh-100 justify-content-center' style={{flexDirection: 'column'}}>
            <div className='meal-header' style={{alignItems: 'center', flexDirection:'column'}}>
                <Link to='/my-points' style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                    <div role='presentation' className='meal-back'><span className='back-meal-modal'><i className='fa-solid fa-chevron-left'></i></span></div>
                    <div style={{display: 'flex', justifyContent: 'center', flex: 1, fontFamily: 'Poppins', fontSize: '22px', fontWeight: 600}}>CO2 émis</div>
                </Link>
            </div>

            <div style={{backgroundColor: 'white', padding: '5px 15px', margin: '10px', marginTop: '50px', flex: 1}}>
                <div style={{display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center', marginBottom: '30px'}}>
                    <div className='d-flex'>
                        <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                    </div>
                    
                    <div className='presence-complete-day'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>
                    
                    <div className='d-flex'>
                        <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                    </div>
                </div>

                { mealsDisplay && reservationInfos.map((meal, key) => 
                    <div key={key} style={{display: 'flex', flexDirection: 'column', borderBottom: '1px solid #DADADA', paddingBottom: '10px', marginBottom: '10px'}}>
                        <div style={{fontSize: '18px', fontWeight: 600, marginBottom: '10px'}}>{meal.name}</div>
                        <div style={{fontStyle: 'italic', color: '#635C5C', display: 'flex'}}><span style={{display: 'flex', flex: 1}}>CO2</span><span style={{display: 'flex', flex: 1}}>{new Intl.NumberFormat('fr-FR').format(parseFloat(meal.co2).toFixed(2)) } kg / 100g</span><i className={`fa-solid fa-circle ${(meal.co2 < 0.5) ? 'dot-good' : (meal.co2 < 1.5) ? 'dot-neither' : 'dot-bad'}`}></i></div>
                    </div>
                ) }

                { mealsDisplay && 0 == reservationInfos.length && 
                    <div style={{display: 'flex', flexDirection: 'column', borderBottom: '1px solid #DADADA', paddingBottom: '10px', marginBottom: '10px'}}>
                        Pas de vote pour cette journée
                    </div>
                }

                { mealsDisplay && reservationInfos.length > 0 && 
                    <div style={{display: 'flex', paddingBottom: '10px', margin: '40px 0', fontSize: '20px', justifyContent: 'center'}}>
                        Total : <span style={{marginLeft: '5px', color: '#FF5001'}}>{new Intl.NumberFormat('fr-FR').format(co2Total.toFixed(2)) } kgCO2 /100g</span>
                    </div>
                }

                { mealsDisplay && reservationInfos.length > 0 && <>
                    <div style={{fontSize: '14px', fontStyle: 'italic', marginBottom: '10px'}}>Votre consommation de CO2 correspond à :</div>
                    <div><i style={{color: '#FF5001', marginRight: '20px', marginBottom: '10px'}} className='fa-solid fa-car'></i> {new Intl.NumberFormat('fr-FR').format(carTotal.toFixed(2)) } km parcourus en voiture</div>
                    <div><i style={{color: '#FF5001', marginRight: '20px'}} className='fa-solid fa-train'></i> {new Intl.NumberFormat('fr-FR').format(trainTotal.toFixed(2)) } km parcourus en TGV</div>
                </>}
            </div>
        </div>
    );
};

export default CO2Details;
