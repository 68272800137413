import React, {useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import InputField from '../../../components/InputField';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';

const RegisterChildren = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#E5E5E5');

    // Global
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { auth, setAuth, setFlash, setShowFlash, setIsLoading, setModal, setShowModal } = useAuth();
    const { register, control, handleSubmit, formState: { errors } } = useForm();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'children',
    });
    const [, setCookie] = useCookies({});

    const modal = async () => {
        setModal({
            title: <div style={{backgroundColor: '#0B50A4', color: '#fff'}} className='d-flex justify-content-center align-items-center flex-column py-2 mb-3'>Renseigner mon code Cantine,</div>,
            content: <div className='d-flex justify-content-center align-items-center flex-column px-4'>
                <p>Le code cantine vous permet de lier votre compte à votre cantine et de pouvoir accéder à ses menus</p>
                <p>Il est communiqué par votre cantine et est accessible dans votre établissement</p>
            </div>
        })
        setShowModal(true)
    }

    // Call Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        let child;

        // Call API
        axiosPrivate.post('/guests/children/register', {
            children: data.children,
        }, {withCredentials: true})
            .then(result => {
                child = result.data.children[0];
                setAuth({ ...auth, childId: child.id });
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, childId: child.id }, { path: '/' })
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {'id': child.id, 'name': child.firstName, 'point': child.jabuPoint}, { path: '/' })
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_children`, {'children': result.data.children, 'default': {'id': child.id, 'name': child.firstName, 'point': child.jabuPoint } }, { path: '/' })
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, true, {path: '/'})

                return axiosPrivate.get('/guests/canteen', {
                    params: {
                        childId: child.id,
                    },
                    withCredentials: true,
                });
            }).then(response => {
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, {id: response.data.canteen.id, name: response.data.canteen.name}, { path: '/' })
                navigate('/confirm-family', { state: { codeFamily: child.codeFamily }});
            }).catch(err => {
                let error;
                if (err?.response?.status === 404) {
                    error = 'Le code Cantine est incorrect pour un enfant';
                } else if(err?.response?.status === 409) {
                    error = 'Un enfant existe déjà avec ce code cantine';
                } else {
                    error = 'Erreur Serveur';
                    navigate('/500');
                }

                setFlash({ message: error });
                setShowFlash(true);
            });

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        // Redirect if choice already made
        if (auth?.canteenId !== null || auth?.childId !== null) {
            navigate('/');
        } else {
            append();
        }
    }, [])

    return (
        <>
            <div className='profil-page d-flex min-vh-100 justify-content-center'>
                <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                    <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                        <div className='d-flex profile-header-back'>
                            <Link to='/register-model'>
                                <span style={{backgroundColor: '#F5F4ED', borderRadius: '20px', padding: '10px' }}>
                                    <i style={{fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                </span>
                            </Link>
                        </div>
                        <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Bonjour {auth?.firstName}</div>
                        <div><img className='logo-tiny' src='/images/logo-tiny.png' alt='Jabu'></img></div>
                    </div>

                    <div className='d-flex flex-column profile-block flex-grow-1'>
                        <div className='user-title d-flex justify-content-center'>
                            Ajouter un enfant et une cantine de rattachement
                        </div>

                        <form className="d-flex flex-column flex-grow-1" onSubmit={handleSubmit(submit)}>
                            <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
                                {fields.map((field, index) => {
                                    let {
                                        refFirstName,
                                        ...inputPropsFirstName
                                    } = register(`children.${index}.firstName`, {
                                        required: 'Prénom obligatoire',
                                    });
                                    let {refLastName, ...inputPropsLastName} = register(`children.${index}.lastName`, {
                                        required: 'Nom obligatoire',
                                    });
                                    let {refCode, ...inputPropsCode} = register(`children.${index}.code`, {
                                        required: 'Code obligatoire',
                                    });

                                    return (
                                        <div className='my-4 d-flex flex-column justify-content-center align-items-center' key={field.id}>
                                            <InputField
                                                color="#000"
                                                label="Prénom"
                                                className="user-input"
                                                error={!!errors.children && !!errors.children[index] && !!errors.children[index].firstName}
                                                helper={errors?.children?.[index]?.firstName?.message}
                                                inputRef={refFirstName}
                                                inputProps={inputPropsFirstName}
                                            ></InputField>
                                            <InputField
                                                color="#000"
                                                label="Nom"
                                                className="user-input"
                                                error={!!errors.children && !!errors.children[index] && !!errors.children[index].lastName}
                                                helper={errors?.children?.[index]?.lastName?.message}
                                                inputRef={refLastName}
                                                inputProps={inputPropsLastName}
                                            ></InputField>
                                            <InputField
                                                color="#000"
                                                label="Code Cantine"
                                                className="user-input"
                                                error={!!errors.children && !!errors.children[index] && !!errors.children[index].code}
                                                helper={errors?.children?.[index]?.code?.message}
                                                inputRef={refCode}
                                                inputProps={inputPropsCode}
                                            ></InputField>
                                            <i role="presentation" className='fa-solid fa-circle-info mb-2' onClick={() => modal()}></i>
                                            <button className='user-child-delete' type="button" onClick={() => remove(index)}>Supprimer</button>
                                        </div>
                                    )
                                })}
                                <div className='d-flex align-items-center flex-column justify-content-center'>
                                    <p className='mb-0'>Ajouter un autre enfant</p>
                                    <button className='user-child-add' type="button" onClick={() => append()}>+</button>
                                </div>

                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <p className='w-100' style={{'fontWeight': 'bold', 'marginTop': '30px'}}>Code cantine ?</p>
                                    <p>Le code cantine vous permet de lier votre compte à votre cantine et de pouvoir accéder à ses menus.</p>
                                    <p>Il est communiqué par votre cantine et est accessible dans votre établissement.</p>
                                </div>

                                <div className='d-flex mt-4 align-items-center flex-column justify-content-center'>
                                    <input className='user-submit' type='submit' value='Suivant'></input>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterChildren;
