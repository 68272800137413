import React from 'react';
import useAuth from '../../hooks/useAuth';

const UnsoldDetails = ({ name, price, components, tags = [], allergens = []}) => {
    let mealComponents, mealTags, mealAllergens, priceBlock = undefined
    const { setShowModal } = useAuth();

    const closeModal = async () => {
        setShowModal(false)
    };

    if( price > 0 ){
        priceBlock = 
            <div className='unsold-price'><i className='fw-bold'>{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price) }</i></div>
    }

    if( components.length > 0 ){
        mealComponents = components.map((component, key) =>
            <div key={key}><i>{component}</i></div>
        );
    }else{
        mealComponents = <div><i>Aucun</i></div>
    }

    if( tags.length > 0 ){
        mealTags = tags.map((tag, key) =>
            <div key={key}><img src={`/images/${tag.icon}`} alt={tag.name}></img><i>{tag.name}</i></div>
        );
    }else{
        mealTags = <div><i>Aucun</i></div>
    }

    if( allergens.length > 0 ){
        mealAllergens = allergens.map((allergen, key) =>
            <div className='d-flex align-items-center' key={key}><img className='allergen' src={`/images/${allergen.icon}`} alt={allergen.name}></img><i>{allergen.name}</i></div>
        );
    }else{
        mealAllergens = <div><i>NC</i></div>
    }

    return (
        <>
            <div className='bg-white rounded m-1 d-flex flex-column p-1'>
                <div className='d-flex'>
                    <div role='presentation' className='unsold-back' onClick={() => closeModal()}>
                        <i className='fa-solid fa-circle-chevron-left'></i>
                        {/* <img src='/images/back-icon-green.png' alt='Retour'></img> */}
                    </div>
                    <div className='meal-name'><u className='fw-bold'>{name}</u></div>
                    {priceBlock}
                </div>

                <div className='unsold-details'>
                    <div className='d-flex flex-column flex-grow-1 flex-shrink-1'>
                        <div className='meal-detail-category'>Composition</div>
                        <div className='unsold-detail-category-data'>{mealComponents}</div>
                    </div>

                    <div className='d-flex flex-column flex-grow-1 flex-shrink-1'>
                        <div className='meal-detail-category'>Labels</div>
                        <div className='unsold-detail-category-data'>{mealTags}</div>
                    </div>

                    <div className='d-flex flex-column flex-grow-1 flex-shrink-1'>
                        <div className='meal-detail-category'>Allergènes</div>
                        <div className='unsold-detail-category-data'>{mealAllergens}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnsoldDetails
