import React from 'react';
import useAuth from '../../hooks/useAuth';

const MealDetails = ({ id, setChecked, getValues, setValue, name, price, components, tags = [], allergens = [], co2 = null, kcal = null, proteen = null, sugar = null, lipids = null, glucids = null, total, setTotal}) => {
    let mealComponents, mealTags, mealAllergens, priceBlock, componentsPhrase = undefined
    const { setShowModal } = useAuth();

    const closeModal = async () => {
        setShowModal(false)
        let dishes = getValues('dishes');
        if (dishes.includes(String(id))) {
            const index = dishes.indexOf(String(id));
            dishes.splice(index, 1);
            setValue('dishes', dishes);
            setTotal(total-price);
            setChecked(false);
        }
    };

    if( price > 0 ){
        priceBlock = 
            <div className='meal-price'><i className='fw-bold'>{ new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price) }</i></div>
    }

    if( components.length > 1 ){
        componentsPhrase = components.join(', ').toLowerCase()
        mealComponents = <label>{componentsPhrase[0].toUpperCase() + componentsPhrase.slice(1)}</label>
    }else{
        mealComponents = <div>NC</div>
    }

    if( tags.length > 0 ){
        mealTags = tags.map((tag, key) =>
            <div key={key}><img style={{width:20}} src={`/images/${tag.icon}`} alt={tag.name}></img>{tag.name}</div>
        );
    }else{
        mealTags = <div>NC</div>
    }

    if( allergens.length > 0 ){
        mealAllergens = allergens.map((allergen, key) =>
            <div className='d-flex align-items-center' key={key}><img className='allergen' src={`/images/${allergen.icon}`} alt={allergen.name}></img>{allergen.name}</div>
        );
    }else{
        mealAllergens = <div>NC</div>
    }

    let co2Class = (co2 < 0.5) ? 'co2-good' : (co2 < 1.5) ? 'co2-neither' : 'co2-bad'

    return (
        <>
            <div className='bg-white rounded m-1 d-flex flex-column p-1'>
                <div className='d-flex'>
                    <div role='presentation' className='meal-back' onClick={() => closeModal()}><span className='back-meal-modal'><i className='fa-solid fa-chevron-left'></i></span></div>
                    <div className='meal-name'>{name}</div>
                    {priceBlock}
                </div>

                <div className='meal-details'>
                    <div className='d-flex flex-column flex-grow-1 flex-shrink-1'>
                        <div className='meal-detail-category'>Composition</div>
                        <div className='metal-detail-category-data'>{mealComponents}</div>
                    </div>

                    <div className='d-flex flex-column flex-grow-1 flex-shrink-1'>
                        <div className='meal-detail-category'>Impact (pour 100g)</div>
                        <div className='metal-detail-category-data'>
                            <span className={`co2 ${co2Class}`}>CO2 : {new Intl.NumberFormat('fr-FR').format(parseFloat(co2).toFixed(2)) }kg</span>
                        </div>
                    </div>

                    <div className='d-flex flex-column flex-grow-1 flex-shrink-1'>
                        <div className='meal-detail-category'>Nutrition (pour 100g)</div>
                        <div className='metal-detail-category-data'>
                            <div className='d-flex'>
                                <span style={{'minWidth': '170px'}} className='d-flex'>Calories</span>
                                <span>{new Intl.NumberFormat('fr-FR').format(parseFloat(kcal).toFixed(2)) }kcal</span>
                            </div>

                            <div className='d-flex'>
                                <span style={{'minWidth': '170px'}} className='d-flex'>Proteines</span>
                                <span>{new Intl.NumberFormat('fr-FR').format(parseFloat(proteen).toFixed(2)) }g</span>
                            </div>

                            <div className='d-flex'>
                                <span style={{'minWidth': '170px'}} className='d-flex'>Glucides</span>
                                <span>{new Intl.NumberFormat('fr-FR').format(parseFloat(glucids).toFixed(2)) }g</span>
                            </div>

                            <div className='d-flex'>
                                <span style={{'minWidth': '170px'}} className='d-flex'>Acides gras saturés</span>
                                <span>{new Intl.NumberFormat('fr-FR').format(parseFloat(lipids).toFixed(2)) }g</span>
                            </div>

                            <div className='d-flex'>
                                <span style={{'minWidth': '170px'}} className='d-flex'>Sucres</span>
                                <span>{new Intl.NumberFormat('fr-FR').format(parseFloat(sugar).toFixed(2)) }g</span>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-column flex-grow-1 flex-shrink-1'>
                        <div className='meal-detail-category'>Labels</div>
                        <div className='metal-detail-category-data'>{mealTags}</div>
                    </div>

                    <div className='d-flex flex-column flex-grow-1 flex-shrink-1'>
                        <div className='meal-detail-category'>Allergènes</div>
                        <div className='metal-detail-category-data'>{mealAllergens}</div>
                    </div>
                </div>

                {/* <div className='mt-1 d-flex flex-column'>
                    {mealAccompaniementsBlock}
                    <div className='d-flex justify-content-center'>
                        <button className='meal-submit meal-detail-submit' onClick={() => chooseMeal()}>{submitText}</button>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default MealDetails
