import axios from 'axios';

const BASE_URL = process.env.REACT_APP_JABU_API_ENDPOINT;

// API without authentication
export default axios.create({
    baseURL: BASE_URL
});

// API with authentication
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
});
