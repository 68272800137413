import React from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const ConfidentialPage = () => {
    const { auth } = useAuth();
    
    return (
        <div className="profil-page d-flex confidential min-vh-100 justify-content-center">
            <div className="d-flex flex-column align-items-center flex-grow-1 flex-shrink-1">
                { auth?.username &&
                    <div className="profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center">
                        <div className="d-flex profile-header-back">
                            <Link to='/my-profile'>
                                <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                    <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                </span>
                            </Link>
                        </div>
                        <div className="d-flex justify-content-center flex-grow-1 profile-header-text">Mon profil</div>
                    </div>
                }

                { null == auth?.username &&
                    <div className="profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center">
                        <div className="d-flex profile-header-back">
                            <Link to='/register'>
                                <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                    <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                </span>
                            </Link>
                        </div>
                        <div className="d-flex justify-content-center flex-grow-1 profile-header-text">Retour</div>
                    </div>
                }   

                <div className="d-flex flex-column profile-block">
                    <div className="user-title d-flex">
                        <div className='d-flex flex-column'>
                            <p>POLITIQUE DE CONFIDENTIALITÉ</p>
                            <p style={{fontSize: '10px'}}>Informations fournies au titre des données à caractère personnel (règlement UE 2016/679 du 27 avril 2016 (RGPD) et loi n°78-17 du 6 janvier 1978)</p>
                        </div>
                    </div>

                    <div className="cgu d-flex flex-column text-start">
                        <p style={{fontWeight: 'bold'}}>PRÉAMBULE</p>
                        <p></p>
                        <p>Les présentes (ci-après la « Politique de confidentialité ») sont à jour au 07 mai 2024.</p>
                        <p>La Politique de confidentialité a vocation à informer les Visiteurs (Site) ou Utilisateurs (Application) sur le ou les traitements de données à caractère personnel les concernant, qu&apos;effectue ou que peut effectuer la société JABU dans le cadre de l&apos;utilisation des Services, de toute commande liée aux Services ou plus généralement de tout usage du site internet accessible à l&apos;adresse https://www.jabu-app.fr/ (ci-après le “Site”) et de l&apos;application accessible à l&apos;adresse https://www.jabu-app.com/ pour les convives et https://pro.jabu-app.com/ pour les établissements (ci-après l&apos;“Application”) ou via un téléchargement sur l’Apple Store ou le Google Play Store®. La présente Politique de confidentialité fait partie intégrante des Conditions Générales de Vente et d’Utilisation (ci-après les « CGVU ») et disponibles sur le « Site » et sur « l’Application ». Aussi, tous les termes non définis ou dont la définition n’est pas rappelée aux présentes ont le sens qui leur est donné dans les CGVU.</p>
                        <p>La société JABU est une société par actions simplifiée au capital de 1.000 euros, ayant son siège social sis 26 bis rue des Berthauds - 93110 ROSNY-SOUS-BOIS, inscrite au R.C.S. de BOBIGNY sous le numéro 980 078 091 (ci-après la « Société »). Son numéro de téléphone est le suivant: +33 (0)6 38 66 57 48 et son adresse de messagerie électronique: contact@jabu-app.com.</p>
                        <p>L’utilisation par tout Utilisateur du Site, de l’Application, de toute application ou logiciel mis à disposition par la Société ou de tout Service qui y serait proposé, vaut acceptation pure et simple par l’Utilisateur des présentes. Aussi tout Utilisateur ne souhaitant pas consentir aux présentes est libre de ne pas consulter ou utiliser le Site, l’Application ou les Services.</p>
                        <p></p>
                        <p></p>
                        <p className="text-center fw-bold">POLITIQUE DE CONFIDENTIALITE DU « SITE »</p>
                        <p></p>
                        <p style={{fontWeight: 'bold'}}>1. FINALITES ET BASES LEGALES DE TRAITEMENT</p>
                        <p>La raison d&apos;être de la collecte de vos données personnelles repose sur notre engagement à offrir une expérience sûre, efficace et personnalisée sur notre site. Les informations collectées nous permettent d&apos;optimiser notre plateforme et de détecter tout usage inapproprié pouvant affecter la sécurité de nos visiteurs. En outre, ces données jouent un rôle crucial dans l&apos;amélioration de nos services, l&apos;adaptation de notre contenu à vos intérêts et la gestion efficace de votre navigation sur le site. Toute information personnelle que vous choisissez de partager avec nous est utilisée dans le but de fournir les services demandés, de communiquer avec vous au sujet nos services, et, avec votre consentement, de vous envoyer des informations marketing susceptibles de vous intéresser.</p>
                        <p>Les traitements exécutés sur nos sites internet sont soumis aux bases légales de l’intérêt légitime et du consentement.</p>
                        <p>Les finalités des traitements des données à caractère personnel des Visiteurs sont les suivantes :</p>
                        <p>- constitution et exploitation d’un fichier de prospects;</p>
                        <p>- publicité : fourniture de publicité ciblées, messages promotionnels, invitation à participer à des enquêtes, notifications et autres informations relatives aux Services et aux intérêts des Visiteurs, offre de contenu spécifique en fonction de la localisation du Visiteur ;</p>
                        <p>- amélioration des Services et des produits et création de nouveaux produits et services : identification des tendances d’utilisation, analyse des données, audit, recherche, reporting, détermination de l’efficacité de la performance des campagnes promotionnelles et évaluation de la performance commerciale ;</p>
                        <p>- exécution d’obligations légales ou des obligations découlant des articles 15 et suivants du règlement UE 2016/679 du 27 avril 2016 (RGPD) : conformité aux obligations légales, résolution d’éventuels litiges, respect des engagements contractuels, prévention de la fraude, exécution d’une tâche dans l’intérêt public;</p>
                        <p>Dans le cas où il serait effectué un traitement ultérieur de données à caractère personnel pour des finalités autres que celles identifiées au présent article, le responsable du traitement désigné à l’article 4.1.2 ci-dessus, informera les Visiteurs concernés de cette nouvelle finalité.</p>
                        <p></p>
                        <p className="fw-bold">2. DONNÉES RECUEILLIES LORS DE L’UTILISATION DU SITE</p>
                        <p>Notre engagement envers la confidentialité et la protection de vos données est au cœur de nos préoccupations chez Jabu. Lorsque vous utilisez notre site, nous veillons à collecter uniquement les informations essentielles, en adhérant strictement au principe de minimisation des données.</p>
                        <p>Lors de l’utilisation du Site, afin de desservir les finalités listées au paragraphe précédent et en fonction de votre consentement ou refus lorsque applicable, les données et informations ci-après énumérées sont susceptibles d’être collectées au moyen de cookies, traceurs ou de tout autres moyens techniques équivalents,</p>
                        <p>- des informations de connexion : modèle d’ordinateur, environnement de connexion, adresse IP, type et version du navigateur internet, version du système d’exploitation, autres logiciels installés dans l’environnement, version de la plateforme mobile, identifiants techniques, rapports d’erreur et données d’exécution, géolocalisation (région, ville ou village) ; et</p>
                        <p>- des données d’utilisation : données consultées, heures et date de consultation, termes recherchés, pages visitées et recherchées par le Visiteur.</p>
                        <p>Pour rappel, les cookies sont des fichiers texte qui peuvent être lus par un serveur web du domaine Jabu-app.fr et qui sont placés directement sur le disque dur ou le SSD de l’Utilisateur. Ils peuvent être utilisés pour stocker les préférences et les paramètres du Visiteur, afin de l’aider lors de sa connexion au Site et lors de l’utilisation des Services. Ils peuvent également permettre la fourniture de publicités ciblées et d’analyser les opérations intervenues sur le Site. Au-delà de la possibilité d’acceptation, refus et paramétrage de cookies proposées par le Site Web, les Visiteurs disposent de moyen de contrôle des cookies à travers leurs préférences de navigateur et d’autres outils. Toutefois, le blocage de certains cookies peut entraîner une dégradation de l’utilisation du Site et/ou une détérioration de l’accès aux Services. Les conséquences en cas de non-collecte de données facultatives sont : l’absence de réception de communications facultatives et la non personnalisation de l’expérience utilisateur.</p>
                        <p></p>
                        <p className="fw-bold">3. DROITS DES VISITEURS</p>
                        <p>Nous respectons vos droits à la confidentialité et vous permettons d&apos;exercer vos droits relatifs à vos données personnelles, conformément à la réglementation applicable, notamment le droit d&apos;accès, de rectification, d&apos;effacement, de limitation du traitement, et la portabilité des données. Si vous souhaitez faire valoir vos droits ou si vous avez des questions concernant l&apos;utilisation de vos données personnelles, n&apos;hésitez pas à nous contacter à contact@jabu-app.com. Pour les résidents de l&apos;Union européenne, il est important de noter que le traitement de vos données peut être nécessaire pour l&apos;exécution d&apos;un contrat ou pour poursuivre des intérêts légitimes non outrepassés par vos droits et libertés.</p>
                        <p>Au regard de la base juridique des traitements comprenant notamment celle du consentement du Visiteur, il dispose des droits suivants, dans les conditions prévues par la réglementation en vigueur:	</p>
                        <p>- l&apos;accès à ses données à caractère personnel;</p>
                        <p>- la rectification des données à caractère personnel lorsqu’elles sont inexactes ou incomplètes;</p>
                        <p>- l’effacement de celles-ci, notamment lorsque ces données ne sont plus nécessaires au regard de la finalité des traitements ou lorsque qu’il a retiré son consentement ou lorsque le traitement concerné est illicite, sous réserve des obligations légales de conservation;</p>
                        <p>- une limitation du traitement de ses données à caractère personnel lorsque l’exactitude des données est en cours de vérification en suite d’une contestation par la personne concernée, lorsque le traitement concerné est illicite et la personne concernée s&apos;oppose à l’effacement des données et exige à la place la limitation de leur utilisation ou lorsque le responsable du traitement n&apos;a plus besoin des données à caractère personnel aux fins du traitement concerné mais celles-ci sont encore nécessaires à la personne concernée pour la constatation, l&apos;exercice ou la défense de droits en justice;</p>
                        <p>- l&apos;opposition de au traitement de ses données à caractère personnel;</p>
                        <p>- l&apos;opposition de à tout moment au traitement de ses données à caractère personnel à des fins de prospection, y compris au profilage;</p>
                        <p>- la portabilité de ses données à caractère personnel dans les conditions de la réglementation en vigueur laquelle dispose notamment que les personnes concernées par un traitement de données à caractère personnel ont le droit de recevoir les données à caractère personnel les concernant qu&apos;elles ont fournies au responsable du traitement, dans un format structuré, couramment utilisé et lisible par machine, et ont le droit de voir transmettre ces données à un autre responsable du traitement;</p>
                        <p>- le retrait par le Visiteur, à tout moment, de son consentement au traitement de ses données à caractère personnel (sans porter atteinte à la licéité du traitement fondé sur le consentement effectué avant le retrait de celui-ci) ; et</p>
                        <p>- le droit de définir des directives relatives au sort de ses données à caractère personnel après son décès.</p>
                        <p>En tant que de besoin, il est stipulé que l&apos;exercice par un Visiteur de son droit à l&apos;effacement de ses données à caractère personnel et/ou l&apos;exercice de son droit de s&apos;opposer au traitement de ses données à caractère personnel et/ou l&apos;exercice de son droit à une limitation du traitement de ses données à caractère personnel et/ou de son droit de retirer son consentement à tout moment au traitement de ses données à caractère personnel (conformément aux stipulations susvisées) conduit à ne pas rendre possible l’accès par le Visiteur aux Services, de sorte que, en pareilles hypothèses (i) si ces droits sont exercés au moment de la commande de Services alors la dite commande ne pourra être effectué par l&apos;Utilisateur (ii) en tout état de cause, à compter de l&apos;exercice par le Visiteur desdits droits, celui-ci ne pourra plus accéder aux Services et le compte du Visiteur récipiendaire de ses données à caractère personnel sera supprimé.</p>
                        <p></p>
                        <p className="fw-bold">4. CONTACT</p>
                        <p className="fw-bold">4.1. IDENTITÉ ET COORDONNÉES DU RESPONSABLE DE TRAITEMENT </p>
                        <p>Le responsable du traitement est la Société : </p>
                        <p className="text-center">
                            JABU<br></br>
                            société par actions simplifiée au capital de 1.000 euros<br></br>
                            26 B rue des Berthauds - 93110 ROSNY-SOUS-BOIS<br></br>
                            R.C.S. BOBIGNY 980 078 091<br></br>
                            TVA intracommunautaire FR 64 980 078 091<br></br>
                            Code NAF / APE 62.01Z
                        </p>
                        <p>Son adresse de messagerie électronique: contact@jabu-app.com </p>
                        <p className="fw-bold">4.2. IDENTITÉ ET COORDONNÉES DU DÉLÉGUÉ À LA PROTECTION DES DONNÉES</p>
                        <p>Le délégué à la protection des données est Monsieur Ismaël TALBI. Les demandes concernant des Visiteurs concernant les données à caractère personnel peuvent être adressées à l’adresse de messagerie électronique: ismael.talbi@jabu-app.com et à l’attention de Monsieur Ismaël TALBI à l’adresse postale JABU SAS, 26 B rue des Berthauds - 93110 ROSNY-SOUS-BOIS.</p>
                        <p className="fw-bold">4.3. RÉCLAMATION AUPRÈS DE LA CNIL</p>
                        <p>Les Visiteurs concernés par des traitements de données à caractère personnel ont la possibilité d&apos;introduire une réclamation auprès de l&apos;autorité de contrôle laquelle est la Commission Nationale de L&apos;Informatique et des Libertés (CNIL): 3 Place de Fontenoy - TSA 80715 - 75334 PARIS 07.</p>
                        <p></p>
                        <p className="fw-bold">5.DESTINATION</p>
                        <p className="fw-bold">5.1 APPLICATION TIERCE</p>
                        <p>L’accès par le Visiteur à une application tierce présente sur le Site peut entraîner le partage avec l’éditeur de cette application tierce de données à caractère personnel concernant le Visiteur, afin notamment, que ce dernier puisse obtenir l’accès à l’application, sous réserve des conditions, des accords de licence et de la politique de confidentialité de l’application tierce.</p>
                        <p className="fw-bold">5.2 LA SOCIÉTÉ ET LES PRESTATAIRES DE SERVICES</p>
                        <p>En fonction du Service, des données à caractère personnel concernant un Visiteur sont susceptibles d’être partagées avec :</p>
                        <p>- la Société et toute société faisant partie de son groupe en ce compris notamment toute filiale ou société affiliée ;</p>
                        <p>- tout prestataire de services, fournisseurs, distributeurs, agents et représentants, y compris, entre autres,  les support client, les fournisseurs de service de messagerie électronique, les lieux d&apos;événements et les prestataires de services, les prestataires de services informatiques (en ce compris hébergeurs), les prestataires de services de marketing, les prestataires de recherche, les sociétés de publipostage, les agents d’expédition.</p>
                        <p></p>
                        <p className="fw-bold">6. TRANSFERT</p>
                        <p>Le Visiteur est informé que le responsable du traitement peut, le cas échéant, effectuer un transfert des données personnelles vers un pays tiers ou à une organisation internationale faisant l&apos;objet d&apos;une décision d&apos;adéquation rendue par la Commission européenne étant précisé que, en présence d&apos;un transfert vers un pays ou une organisation internationale ne faisant pas l&apos;objet d&apos;une décision d&apos;adéquation, cela ne pourra être effectué qu&apos;à la condition que soient mises en place les garanties appropriées et que les personnes concernées par les traitements de données à caractère personnel disposent de droits opposables et de voies de recours effectives, dans les conditions de la Réglementation en vigueur.</p>
                        <p></p>
                        <p className="fw-bold">7. CONSERVATION DES DONNÉES À CARACTÈRE PERSONNEL </p>
                        <p>Les données à caractère personnel des Visiteurs sont conservées aussi longtemps que nécessaire pour fournir le Service et exécuter les obligations qui incombent à la Société en vertu d’un contrat, de la loi ou d’un règlement.</p>
                        <p>Aussi les principales durées de conversation des données à caractère personnel se rapportant aux Visiteurs du Site:</p>
                        <p>- données se rapportant aux prospects : 3 (trois) ans à compter du dernier contact avec le prospect ;</p>
                        <p>- données collectées au cours de l’inscription à un Service (processus d’inscription interrompu) : trente (30) jours à partir de la saisie de l’adresse de courrier électronique par le Visiteur ;</p>
                        <p>- données relatives à l’exercice d’un droit par un Visiteur : cinq (5) ans en plus de l’année de la demande.</p>
                        <p></p>
                        <p className="fw-bold">8. SÉCURITÉ DES DONNÉES </p>
                        <p>La Société met en œuvre des mesures techniques et organisationnelles visant à garantir un niveau de sécurité approprié aux informations personnelles traitées. Ces mesures visent à assurer l&apos;intégrité, la confidentialité et la disponibilité des informations personnelles et notamment des données à caractère personnel.</p>
                        <p className="fw-bold">8.1 GESTION DES VIOLATIONS DE DONNEES PERSONNELLES</p>
                        <p>Chez Jabu, la sécurité de vos données personnelles est une priorité absolue. Nous avons mis en place une procédure rigoureuse de gestion des violations de données personnelles. Cette procédure nous permet d&apos;identifier rapidement toute suspicion de violation de données et de prendre les mesures nécessaires pour y répondre efficacement.</p>
                        <p>En cas de violation avérée, nous nous engageons à respecter scrupuleusement les obligations réglementaires en vigueur, y compris la notification de la violation à l&apos;autorité de contrôle compétente, comme la CNIL, dans un délai de 72 heures. De plus, nous nous assurons d&apos;informer les personnes concernées sans délai indû lorsque la violation présente un risque élevé pour leurs droits et libertés.</p>
                        <p>Suite à la découverte d&apos;une violation, nous mettons en œuvre un plan d&apos;actions détaillé visant à suivre et à exécuter les mesures correctrices nécessaires pour prévenir de futures violations. Ce plan d&apos;actions est conçu pour renforcer nos mécanismes de sécurité et minimiser les risques pour les données personnelles que nous traitons.</p>
                        <p></p>
                        <p className="fw-bold">9. MODIFICATION ET ACTUALISATION DES PRÉSENTES</p>
                        <p>La Politique de confidentialité pourra faire l&apos;objet de modifications rectificatives ou d&apos;une actualisation. Toute modification sera accompagnée de l’inscription, sur cette page, de la dernière date de révision. À ce titre, le Visiteur est invité à prendre connaissance régulièrement de la dernière version des présentes, accessible en temps réel sur le Site. </p>
                        <p>La modification de la Politique de confidentialité pourra faire l’objet d’une notification temporaire sur le Site ou par tout moyen, en ce compris par courrier électronique. </p>
                        <p></p>
                        <p></p>
                        <p className="text-center fw-bold">POLITIQUE DE CONFIDENTIALITE DE « L’APPLICATION »</p>
                        <p></p>
                        <p className="fw-bold">1. BASES JURIDIQUES DES TRAITEMENTS DE DONNÉES À CARACTÈRE PERSONNEL</p>
                        <p>Les bases juridiques des traitements sont le consentement de l’Utilisateur, la nécessité de l’exécution de mesures précontractuelles ou contractuel afin de bénéficier des Services, ainsi que la nécessité, pour la Société, de poursuivre son intérêt légitime, et notamment de pouvoir administrer sa relation avec l’Utilisateur et de pouvoir conserver une preuve des transactions.</p>
                        <p>L’Utilisateur est informé que dans le cadre de toute relation contractuelle qu’il souhaiterait nouer avec la Société, son refus de fournir les données à caractère personnel demandées est susceptible d’empêcher son accès aux Services et la bonne exécution du contrat.</p>
                        <p></p>
                        <p className="fw-bold">2. FINALITÉS DES TRAITEMENTS DES DONNÉES À CARACTÈRE PERSONNEL</p>
                        <p>Chez Jabu, nous accordons une importance primordiale à la transparence et à la sécurité dans le traitement de vos données à caractère personnel. C&apos;est pourquoi nous avons établi un registre de traitements, fidèlement mis à jour, où sont consignés tous les traitements de données personnelles que nous effectuons. Ce registre est un élément clé de notre engagement envers la protection de vos données, car il nous permet de documenter de manière exhaustive les finalités de ces traitements, ainsi que les mesures techniques et organisationnelles mises en place pour en assurer la sécurité.</p>
                        <p>Dans l&apos;optique de renforcer continuellement la sécurité de vos données, nous procédons à des analyses régulières des traitements effectués au sein de notre organisation. Ces analyses sont cruciales, car elles nous permettent d&apos;identifier et d&apos;appliquer les mesures techniques et organisationnelles les plus adéquates pour garantir la protection de vos données contre tout accès non autorisé, perte ou divulgation. Notre objectif est d&apos;assurer que chaque traitement de données à caractère personnel est réalisé dans le respect des principes de minimisation des données et de sécurité maximale.</p>
                        <p>Dans notre registre de traitements, nous veillons à distinguer clairement les finalités spécifiques liées directement au périmètre de nos prestations. Cela nous permet de vous garantir que seules les données nécessaires à la réalisation de ces services sont collectées et traitées.</p>
                        <p>Les finalités des traitements des données à caractère personnel des Utilisateurs sont les suivantes:</p>
                        <p>- gestion du compte de l’Utilisateur « Convive » : gestion de l’inscription, des identifiants et des mots de passe ;</p>
                        <p>- gestion du compte de l’Utilisateur « Cantine » : activation et gestion de l’accès au profil de l’Utilisateur, des identifiants et des mots de passe, gestion des abonnements ;</p>
                        <p>- gestion de la relation contractuelle avec l’Utilisateur « Cantine »: envoi d’informations sur l’évolution des offres, envoi de propositions commerciales, offre de contenu personnalisé,; traitement des demandes, commandes, téléchargements, souscriptions aux abonnements, facturation, paiement et exécution des transactions ou des contrats ;</p>
                        <p>- amélioration des Services et des produits et création de nouveaux produits et services : identification des tendances d’utilisation, analyse des données, audit, recherche, reporting ;</p>
                        <p>- exécution d’obligations légales ou des obligations découlant des articles 15 et suivants du règlement UE 2016/679 du 27 avril 2016 (RGPD) : conformité aux obligations légales, résolution d’éventuels litiges, respect des engagements contractuels, prévention de la fraude, exécution d’une tâche dans l’intérêt public </p>
                        <p>Dans le cas où il serait effectué un traitement ultérieur de données à caractère personnel pour des finalités autres que celles identifiées au présent article, le responsable du traitement désigné à l’article 4.1 ci-dessus, informera les Utilisateurs concernés de cette nouvelle finalité.</p>
                        <p></p>
                        <p className="fw-bold">3. DONNÉES À CARACTÈRE PERSONNEL FAISANT L’OBJET D’UNE COLLECTE</p>
                        <p>Chez Jabu, nous pratiquons la minimisation des données, une approche respectueuse qui consiste à collecter uniquement les informations essentielles à la fourniture de nos services. Les données que nous recueillons sont donc strictement nécessaires pour l&apos;exécution de la prestation que vous attendez de nous. Cette démarche comprend les informations que vous nous fournissez directement, telles que votre nom, adresse e-mail, et coordonnées, essentielles pour créer votre compte, traiter vos demandes ou vous tenir informé de l&apos;évolution de nos services.</p>
                        <p>Les catégories de données à caractère personnel collectées sont les suivantes :</p>
                        <p>Les données à caractère personnel que nous collectons directement auprès des Utilisateurs dépendent de la relation qu’ils entretiennent avec le Service et peuvent inclure :</p>
                        <p>- des données d’identification : nom(s), prénom(s), date de naissance,</p>
                        <p>- des donnés de contact : adresse postale (facultatif), adresse de messagerie électronique, identifiants de réseaux sociaux (facultatif) et numéro(s) de téléphone (facultatif) ;</p>
                        <p>Les conséquences des non saisies des informations facultatives portent d’une part sur les modalités de connexion, d’autre part sur les modalités de prise de contact pour le numéro de téléphone.</p>
                        <p>- des données nécessaire à la réalisation des Services : identifiants de connexion au Service, (telles que nom d’utilisateur, mot de passe et autres informations liées à la sécurité de ces identifiants, en ce compris les réponses aux questions de sécurité), préférences gustatives (gustation ou allergies (ces dernières données sont purement déclaratoires, volontairement partagées par l’Utilisateur et non médicalement étayées, de telle sorte qu’elles ne sauraient s’analyser en des données concernant la santé de l’Utilisateur au sens de l’article 9 du règlement UE 2016/679 du 27 avril 2016 (RGPD)), historique d’achat, catégorie d’abonnement, commentaires, requêtes, questions et informations envoyées au service client, factures ; </p>
                        <p>- le cas échéant, informations de paiement (coordonnées bancaires,) afin d&apos;assurer le règlement des Services : numéro de carte de crédit ou de carte de débit, date de validité, nom et code de vérification de la carte, tout identifiant permettant l’utilisation d’un service de paiement mise à disposition par une tierce partie (e.g., PayPal, Apple Pay, Google Pay…) ; et</p>
                        <p>- des données portant sur la structure que représente l’Utilisateur « Cantine » : dénomination sociale, adresse postale, capital social, numéro d’identification au R.C.S. ou au répertoire des métiers, numéro de TVA intracommunautaire, numéro(s) de téléphone, adresse de messagerie électronique, nom et prénom du ou des représentants légaux.</p>
                        <p></p>
                        <p className="fw-bold">4. CONTACT</p>
                        <p className="fw-bold">4.1. IDENTITÉ ET COORDONNÉES DU RESPONSABLE DE TRAITEMENT </p>
                        <p>Le responsable du traitement est la Société : </p>
                        <p className="text-center">
                            JABU<br></br>
                            société par actions simplifiée au capital de 1.000 euros<br></br>
                            26 B rue des Berthauds - 93110 ROSNY-SOUS-BOIS<br></br>
                            R.C.S. BOBIGNY 980 078 091<br></br>
                            TVA intracommunautaire FR 64 980 078 091<br></br>
                            Code NAF / APE 62.01Z
                        </p>
                        <p>Son numéro de téléphone est le suivant: +33 (0)6 38 66 57 48 et son adresse de messagerie électronique: contact@jabu-app.com.</p>
                        <p className="fw-bold">4.2. IDENTITÉ ET COORDONNÉES DU DÉLÉGUÉ À LA PROTECTION DES DONNÉES</p>
                        <p>Le délégué à la protection des données est Monsieur Ismaël TALBI. Les demandes concernant des Utilisateurs concernant les données à caractère personnel peuvent être adressées à l’adresse de messagerie électronique: ismael.talbi@jabu-app.com à l’attention de Monsieur Ismaël TALBI à l’adresse postale JABU SAS, 26 B rue des Berthauds - 93110 ROSNY-SOUS-BOIS.</p>
                        <p className="fw-bold">4.3. RÉCLAMATION AUPRÈS DE LA CNIL</p>
                        <p>Les Utilisateurs concernés par des traitements de données à caractère personnel ont la possibilité d&apos;introduire une réclamation auprès de l&apos;autorité de contrôle laquelle est la Commission Nationale de L&apos;Informatique et des Libertés (CNIL): 3 Place de Fontenoy - TSA 80715 - 75334 PARIS 07.</p>
                        <p></p>
                        <p className="fw-bold">5. PROTECTION DE LA VIE PRIVÉE DES ENFANTS ET ÂGE MINIMUM DES UTILISATEURS</p>
                        <p>Le recueil de données à caractère personnel ne peut concerner que les personnes âgées d&apos;au moins quinze (15) ans lors de leur collecte, sauf dans le cas où le mineur de moins de quinze (15) ans consent à cette collecte et que ce consentement du mineur est accompagné du consentement d’au moins un titulaire de l’autorité parentale le concernant (l’autorité parentale s’entend au sens que lui donne l’article 371-1 du Code civil). Le recueil de données à caractère personnel est indispensable à l’utilisation de l’Application et des Services, aussi les mineurs de moins de quinze (15) ans ne pourront accéder aux Services qu&apos;à la condition d’y avoir être autorisés par le ou les titulaires de l’autorité parentale les concernant de sorte que, en sollicitant les Services et/ou en communiquant des données à caractère personnel sur le Site, les Utilisateurs déclarent et garantissent qu&apos;ils sont âgés d&apos;au moins 15 ans ou qu&apos;ils sont autorisés à utiliser le Site et à transmettre leurs données à caractère personnel par le titulaire de la responsabilité parentale les concernant.</p>
                        <p>Si vous êtes le parent ou le tuteur légal d&apos;un enfant de moins de quinze ans qui a établi un compte chez Jabu sans votre approbation, nous vous invitons à communiquer avec nous immédiatement en utilisant les informations fournies dans ce document. Suite à votre prise de contact, nous agirons promptement pour répondre à votre demande et mettre en place les ajustements nécessaires en vue de protéger les données de votre enfant.</p>
                        <p></p>
                        <p className="fw-bold">6. DESTINATION</p>
                        <p>Lorsqu’un Utilisateur accède à l’Application par l’intermédiaire d’un abonnement administré par sa structure ou sa propre société, certaines données d’utilisation collectées par l’Application peuvent être accessibles et partagées avec le gestionnaire d’abonnement de votre structure aux fins d’analyse de l’utilisation, de la gestion de l’abonnement. Ces données ne sont pas individuelles.</p>
                        <p></p>
                        <p className="fw-bold">7. TRANSFERT</p>
                        <p>Aucun transfert transfrontalier n’est réalisé dans le cadre des traitements réalisés via l’Application.</p>
                        <p></p>
                        <p className="fw-bold">8. CONSERVATION DES DONNÉES À CARACTÈRE PERSONNEL </p>
                        <p>Jabu prête attention à ne conserver les données à caractère personnel des Utilisateurs que le temps nécessaire pour fournir le Service et exécuter les obligations qui incombent à la Société en vertu d’un contrat, de la loi ou d’un règlement.</p>
                        <p>Aussi les principales durées de conversation des données à caractère personnel se rapportant aux Utilisateurs de l’Application:</p>
                        <p>- données d’identification et de contact d’un Utilisateur titulaire d’un compte : pendant toute la durée de la relation contractuelle (tant que l&apos;Utilisateur n&apos;a pas manifesté son intention de ne plus être un client de la Société ou de ne plus voir conservées ses données personnelles, et ce via une demande effectuée par ses soins adressée à l&apos;adresse suivante : contact@jabu-app.com), ce dans la limite maximale de 3 (trois) ans à compter soit de la dernière commande de prestations effectuée par l&apos;Utilisateur via le Site ou l’Application, soit de la date de résiliation du dernier Abonnement conclu par l’Utilisateur (date la plus récente), délai au terme duquel les données à caractère personnel ne sont plus conservées ;</p>
                        <p>- données collectées au cours de l’inscription à un Service (processus d’inscription interrompu) : trente (30) jours à partir de la saisie de l’adresse de courrier électronique par l’Utilisateur ;</p>
                        <p>- coordonnées bancaires: pendant la durée de la relation contractuelle, avec une conservation ultérieure de cinq (5) ans des documents bancaires relatifs aux transactions ;</p>
                        <p>- données relatives aux cartes bancaires (traitées par les prestataires de paiement en qualité de sous-traitant) : conservation par les prestataires de paiement pendant la durée qui leur est nécessaire pour fournir le service en leur qualité de sous-traitant ;</p>
                        <p>- données relatives à l’exécution du contrat (factures, historique des achats, paiements etc.) : dix (10) ans à compter soit de la dernière commande de prestations effectuée par l&apos;Utilisateur via le Site ou l’Application, soit de la date de résiliation du dernier Abonnement conclu par l’Utilisateur (date la plus récente) ;</p>
                        <p>- données relatives à l’exercice d’un droit par un Utilisateur : cinq (5) ans en plus de l’année de la demande.</p>
                        <p></p>
                        <p className="fw-bold">9. SÉCURITÉ DES DONNÉES </p>
                        <p>La Société met en œuvre des mesures techniques et organisationnelles visant à garantir un niveau de sécurité approprié aux informations personnelles traitées. Ces mesures visent à assurer l&apos;intégrité, la confidentialité et la disponibilité des informations personnelles et notamment des données à caractère personnel.</p>
                        <p className="fw-bold">9.1. GESTION DES VIOLATIONS DE DONNEES PERSONNELLES</p>
                        <p>Chez Jabu, la sécurité de vos données personnelles est une priorité absolue. Nous avons mis en place une procédure rigoureuse de gestion des violations de données personnelles. Cette procédure nous permet d&apos;identifier rapidement toute suspicion de violation de données et de prendre les mesures nécessaires pour y répondre efficacement.</p>
                        <p>En cas de violation avérée, nous nous engageons à respecter scrupuleusement les obligations réglementaires en vigueur, y compris la notification de la violation à l&apos;autorité de contrôle compétente, comme la CNIL, dans un délai de 72 heures. De plus, nous nous assurons d&apos;informer les personnes concernées sans délai indû lorsque la violation présente un risque élevé pour leurs droits et libertés.</p>
                        <p>Suite à la découverte d&apos;une violation, nous mettons en œuvre un plan d&apos;actions détaillé visant à suivre et à exécuter les mesures correctrices nécessaires pour prévenir de futures violations. Ce plan d&apos;actions est conçu pour renforcer nos mécanismes de sécurité et minimiser les risques pour les données personnelles que nous traitons.</p>
                        <p></p>
                        <p className="fw-bold">10. DROITS DE L’UTILISATEUR POUVANT ÊTRE EXERCÉS AUPRÈS DU RESPONSABLE DU TRAITEMENT</p>
                        <p>Au regard de la base juridique des traitements comprenant notamment celle du consentement de l’Utilisateur, l’Utilisateur dispose des droits suivants, dans les conditions prévues par la réglementation en vigueur:</p>
                        <p>- l&apos;accès à ses données à caractère personnel ;</p>
                        <p>- la rectification des données à caractère personnel lorsqu’elles sont inexactes ou incomplètes ;</p>
                        <p>- l’effacement de celles-ci, notamment lorsque ces données ne sont plus nécessaires au regard de la finalité des traitements ou lorsque l’Utilisateur a retiré son consentement ou lorsque le traitement concerné est illicite, sous réserve des obligations légales de conservation ;</p>
                        <p>- une limitation du traitement de ses données à caractère personnel lorsque l’exactitude des données est en cours de vérification en suite d’une contestation par la personne concernée, lorsque le traitement concerné est illicite et la personne concernée s&apos;oppose à l’effacement des données et exige à la place la limitation de leur utilisation ou lorsque le responsable du traitement n&apos;a plus besoin des données à caractère personnel aux fins du traitement concerné mais celles-ci sont encore nécessaires à la personne concernée pour la constatation, l&apos;exercice ou la défense de droits en justice;</p>
                        <p>- l&apos;opposition de l&apos;Utilisateur au traitement de ses données à caractère personnel ;</p>
                        <p>- l&apos;opposition de l&apos;Utilisateur à tout moment au traitement de ses données à caractère personnel à des fins de prospection, y compris au profilage ;</p>
                        <p>- la portabilité de ses données à caractère personnel dans les conditions de la réglementation en vigueur laquelle dispose notamment que les personnes concernées par un traitement de données à caractère personnel ont le droit de recevoir les données à caractère personnel les concernant qu&apos;elles ont fournies au responsable du traitement, dans un format structuré, couramment utilisé et lisible par machine, et ont le droit de voir transmettre ces données à un autre responsable du traitement ;</p>
                        <p>- le retrait par l&apos;Utilisateur, à tout moment, de son consentement au traitement de ses données à caractère personnel (sans porter atteinte à la licéité du traitement fondé sur le consentement effectué avant le retrait de celui-ci) ; et</p>
                        <p>- le droit de définir des directives relatives au sort de ses données à caractère personnel après son décès.</p>
                        <p>L&apos;exercice des droits tels qu&apos;identifiés au présent article est réalisé par l’Utilisateur auprès du responsable du traitement au moyen d&apos;une demande effectuée par ses soins et adressée à la Société à l&apos;adresse suivante: contact@jabu-app.com.</p>
                        <p>En tant que de besoin, il est stipulé que l&apos;exercice par un Utilisateur de son droit à l&apos;effacement de ses données à caractère personnel et/ou l&apos;exercice de son droit de s&apos;opposer au traitement de ses données à caractère personnel et/ou l&apos;exercice de son droit à une limitation du traitement de ses données à caractère personnel et/ou de son droit de retirer son consentement à tout moment au traitement de ses données à caractère personnel (conformément aux stipulations susvisées) conduit à ne pas rendre possible l’accès par l’Utilisateur aux Services, de sorte que, en pareilles hypothèses (i) si ces droits sont exercés au moment de la commande de Services alors la dite commande ne pourra être effectué par l&apos;Utilisateur (ii) en tout état de cause, à compter de l&apos;exercice par l&apos;Utilisateur desdits droits, celui-ci ne pourra plus accéder aux Services et le compte de l’Utilisateur récipiendaire de ses données à caractère personnel sera supprimé.</p>
                        <p></p>
                        <p className="fw-bold">11. MODIFICATION ET ACTUALISATION DES PRÉSENTES</p>
                        <p>La Politique de confidentialité pourra faire l&apos;objet de modifications rectificatives ou d&apos;une actualisation. Toute modification sera accompagnée de l’inscription, sur cette page, de la dernière date de révision. À ce titre, l&apos;Utilisateur est invité à prendre connaissance régulièrement de la dernière version des présentes, accessible en temps réel sur l’Application.</p>
                        <p>La modification de la Politique de confidentialité pourra faire l’objet d’une notification temporaire sur l’Application ou par tout moyen, en ce compris par courrier électronique.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfidentialPage;
