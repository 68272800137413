import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
//import useRefreshToken from '../../hooks/useRefreshToken';

const UserDeleteCanteens = () => {
    // Global
    const axiosPrivate = useAxiosPrivate();
    const [displayUserCanteen, setDisplayUserCanteen] = useState(false);
    const [canteensList, setCanteensList] = useState([]);
    const { auth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const navigate = useNavigate();

    const getCanteens = async () => {
        setIsLoading(true);
        const result = await axiosPrivate.get('/guests/canteen/list', { withCredentials: true });
        setCanteensList(result.data.canteens);
        setDisplayUserCanteen(true);
        setIsLoading(false);
    };

    useEffect(() => {
        if (auth?.canteenId === null) {
            navigate('/my-profile');
        } else {
            getCanteens();
        }
    }, []);

    const submit = async () => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            const canteenElements = document.querySelectorAll('input[type="checkbox"]:checked')
            let canteensId = []
            canteenElements.forEach((canteenElement) => (
                canteensId.push(parseInt(canteenElement.value))
            ))

            if (canteensId.length > 0) {
                canteensId.forEach(canteenId => {
                    axiosPrivate.delete('/guests/canteen',{
                        data: {
                            canteen_id: canteenId,
                        },
                        withCredentials: true
                    });
                })

                setFlash({ message: 'La suppression a été effectuée', type: 'success' });
                setShowFlash(true);
            }
            window.location = '/my-canteens'
        } catch (err) {
            setFlash({ message: 'Une erreur est survenue' });
            setShowFlash(true);
            console.log(err)
        }

        // Loader
        setIsLoading(false);
    }

    return (
        <>
            { displayUserCanteen &&
                <div className='profil-page d-flex min-vh-100 justify-content-center flex-column'>
                    <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                        <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                            <div className='d-flex profile-header-back'>
                                <Link to='/my-profile'>
                                    <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px' }}>
                                        <i style={{color: '#0B50A4', fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                    </span>
                                </Link>
                            </div>
                            <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Mon profil</div>
                        </div>

                        <div className='d-flex flex-column profile-block'>
                            <div className='user-title d-flex justify-content-center'>
                                Ma cantine
                            </div>

                            <div className='d-flex flex-column align-items-center'>
                                <span><em>Supprimer une cantine</em> <img style={{height: '24px', marginLeft: '50px'}} alt="trash" src="/images/trash.png"></img></span>
                                { canteensList.length > 0 ?
                                    canteensList.map((canteen) => (
                                        <div role='presentation' key={canteen.id} className='user-canteen'>
                                            <p className='d-flex align-items-center justify-content-between'>
                                                <span>{canteen.name}</span>

                                                <input className='delete-canteen' type='checkbox' name='canteens' disabled={canteen.id === auth.canteenId} defaultValue={canteen.id}></input>
                                            </p>
                                        </div>
                                    )) :
                                    <span>Vous n&apos;avez pas encore de cantine</span>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-end flex-column justify-content-center align-items-center'>
                        <button className='user-submit' type='submit' onClick={ submit }>
                            Supprimer
                        </button>

                        <Link to='/my-canteens'><button className='cancel-canteen'>Annuler</button></Link>
                    </div>
                </div>
            }
        </>
    )
}

export default UserDeleteCanteens;
