import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import MealCard from './MealCard';

const MealSection = ({ userAllergens=null, userDietaryConstraints=null, getValues, setValue, category, name, meals, total, setTotal, accompaniements = [], register, expired=false }) => {
    const mealsCard = meals.map((meal, key) =>
        <SwiperSlide key={key}><MealCard expired={expired} userAllergens={userAllergens} userDietaryConstraints={userDietaryConstraints} getValues={getValues} setValue={setValue} id={meal.id} register={register} total={total} setTotal={setTotal} key={key} category={category} name={meal.name} co2={meal.co2} minCO2={meal.min_co2} kcal={meal.kcal} proteen={meal.proteen} glucids={meal.glucids} lipids={meal.lipids} sugar={meal.sugar} price={meal.price} components={meal.ingredients.split(', ')} tags={meal.tags} allergens={meal.allergens} dietaryConstraints={meal.dietary_constraints} accompaniements={accompaniements}></MealCard></SwiperSlide>
    );

    return (
        <>
            <div>
                <div className='meal-section'>
                    <span className='meal-section-name'>{ name }</span>
                </div>

                <div className='meal-list'><Swiper slidesPerView="2.5">{mealsCard}</Swiper></div>
            </div>
        </>
    )
}

export default MealSection
